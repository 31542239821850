<template>
<div>
  
                        

      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    


            <h4 class="col ml-3 font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> List Des Productions (Archivées)  </h4> 

                    <div class="col">

                                    <b-button pill size="sm"  class="m-1 float-right text-secondary" variant="link" @click="NewBon">
                                                <i class="fa fa-home fa-2x"></i>
                                    </b-button>

                                    <b-button pill size="sm"  class="m-2 float-right" variant="info" @click="fetchList">
                                        <i class="fa fa-sync-alt"></i>
                                        <span> Actualiser</span> 
                                    </b-button>
                    </div>
            </div>
       



        <b-table 
        id="main-table"
        :per-page="perPage"
        :current-page="currentPage"

        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        >
            <template #cell(actions)="row">

                    <b-button pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="afich_sorti_click(row.item)">
                                <i class="fa fa-clipboard-check "></i>
                        Sorties
                    </b-button>
                    

                    <b-button  pill size="sm" class="mr-2 float-right" variant="outline-info" @click="Ckick_exportDox(row.item,1)">
                            <i class="fa fa-print"></i>
                    
                    </b-button>

                    <b-button pill size="sm" class="mr-2 float-right" variant="outline-info" @click="Ckick_exportDox(row.item,0)">
                            <i class="fa fa-bars"></i>
                    
                    </b-button>
                </template>
        
        </b-table>
                <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>

      </div>


    <!-- le modal -->
    <b-modal id="detailModal" :title=curentBN size="lg" hide-footer>


       <b-table 
        small 
        borderless



                :items="itemslistprod" 
                :fields="fieldstt">
                        <template #cell(show_details)="row">
                            <b-button pill size="sm" class="mr-2 float-right" variant="outline-primary" @click="row.toggleDetails">
                                <i class="fa fa-bars"></i>
                            {{ row.detailsShowing ? 'Masquer' : 'Afficher'}}  les détails
                            </b-button>
                        </template>

                <template #row-details="row">
                    <div>
                        <div class="row">
                                <div class="col-5 card m-2 p-0 bg-light">
                                    <b-table :items="row.item.data_entres" :fields="fieldsEntr" small>
                                      <template #head(nom_entre)="data">
                                                <span class="text-info font-weight-light">{{ data.label }}</span>
                                       </template>    
                                       <template #head(qte_init)="data">
                                                <span class="text-info font-weight-light">{{ data.label }}</span>
                                       </template>   
                                       <template #head(qte)="data">
                                                <span class="text-info font-weight-light">{{ data.label }}</span>
                                       </template> 
                                       <template #head(unite)="data">
                                                <span class="text-info font-weight-light">{{ data.label }}</span>
                                       </template> 

                                       </b-table> 

                                    <div class="h-100">
                                    </div>
                                    <div class="d-flex justify-content-center text-secondary">
                                    <h5 class="font-weight-lighter float-bottom">Total  : {{row.item.data_entres.reduce((a, b) => +a + +b.qte, 0)}}</h5>
                                    </div>
                                </div>
                                <div class="col card m-2 p-0 bg-light">
                                    <b-table :items="row.item.data_sortie" :fields="fieldsSorties" small> 

                                            <template #head(nom_sortie)="data">
                                                <span class="text-primary font-weight-light">{{ data.label }}</span>
                                            </template>    
                                            <template #head(qte_prod)="data">
                                                <span class="text-primary font-weight-light">{{ data.label }}</span>
                                            </template>  
                                            <template #head(unite_prod)="data">
                                                <span class="text-primary font-weight-light">{{ data.label }}</span>
                                            </template>  
                                            <template #head(qte_stock)="data">
                                                <span class="text-primary font-weight-light">{{ data.label }}</span>
                                            </template>     
                                            <template #head(unite_stock)="data">
                                                <span class="text-primary font-weight-light">{{ data.label }}</span>
                                            </template>  
                                            <template #head(nom_lot)="data">
                                                <span class="text-primary font-weight-light">{{ data.label }}</span>
                                            </template> 
                                    </b-table> 


                                    <div class="h-100">
                                    </div>

                                    <div class=" d-flex justify-content-center text-secondary">
                                    <h5 class="font-weight-lighter">Total Prod : {{row.item.data_sortie.reduce((a, b) => +a + +b.qte_prod, 0)}}</h5>
                                    </div>

                                </div>
                        </div>

                    </div>
                </template>
                </b-table>
    </b-modal>
    <!-- les trucs public -->

<loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";
import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun, BorderStyle } from "docx";
import { saveAs } from 'file-saver';

export default {
    computed: {
              rows_pagin() {
        return this.itemslist.length
      },
            user_dep() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.dep;
      }
      else
      {
            return 0;
      }
    },
        d_gst_livrais_val() {
      return this.$store.getters.get_gst_livrais_val;
    },

    },
    mounted(){
        this.fetchList()
    },
	data () {
		return {
            perPage: 10,
            currentPage: 1,


            curentBN:'',

            itemslist: [],

            fields: [
                {key: 'nom', label: 'N°', sortable: true},
                {key: 'date', label: 'Date', sortable: true},
                {key: 'description', label: 'Description', sortable: true},
                {key: 'user', label: 'Utilisateur', sortable: true},
                { key: 'actions', label: '' }
            ],
            //public details:

            fieldsEntr: [
				{key: 'nom_entre', label: 'Entrée'},
                {key: 'qte_init', label: 'Stock'},
                {key: 'qte', label: 'Quantité'},
            ],

            fieldsSorties: [
				{key: 'nom_sortie', label: 'Produit'},
                {key: 'qte_prod', label: 'Qté Prod'},
				{key: 'qte_stock', label: 'Qté Stock'},
            ],
        fieldstt: [
                {key: 'nom', label: ''},

                {key: 'show_details', label: ''}
                ],

            isLoading: false
		}
	},
    validations: {
            itemForm: {
                nom: {
                    required
                }
            }
    },
    methods :{

        Ckick_exportDox(rowitem,typ)
        {
                            this.isLoading=true;
                            this.$http.get('/productionProcess/'+rowitem.id)
                            .then((result) => {
                                        this.itemslistprod = result.data.data
                                        this.isLoading=false;

                                        //telecharger rapp
                                        if(typ==1)
                                        {
                                        this.exportDox(rowitem)
                                        }
                                        else
                                        {
                                        this.curentBN=rowitem.nom + " ( "+rowitem.date+" )"
                                        this.$root.$emit('bv::show::modal', 'detailModal')
                                        }

                            })
                            .catch(error=> {
                                        console.log(error)
                                        this.isLoading=false;
                                        this.flashMessage.show({
                                        status: 'error',
                                        title: 'Erreur de Chargement',
                                        message: 'Impossible charger les données.. Actualisez et Réessayez'
                                        })
                            });
        },





                exportDox(rowitem)
                        {


                        let datrwglobal=[]


                        let datrw=[]


                        this.itemslistprod.forEach(function(item){


//le entres 
                        let datrwEntre =[new TableRow({
                                    children: [
                                        new TableCell({
                                            width: {
                                                size: 50,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Entrées",
                                                            spacing: {
                                                            before: 100,
                                                            after: 100
                                                        },
                                                        heading: HeadingLevel.HEADING_5,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                        new TableCell({
                                            width: {
                                                size: 50,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Quantité",
                                                            spacing: {
                                                            before: 100,
                                                            after: 100
                                                        },
                                                        heading: HeadingLevel.HEADING_5,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                                    ],
                                })]




                        item.data_entres.forEach(function(ee){


                        let rentre = new TableRow({
                                    children: [
                                        new TableCell({
                                            width: {
                                                size: 50,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: ee.nom_entre,
                                                        spacing: {
                                                        before: 50,
                                                        after: 50
                                                    },
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                        new TableCell({
                                            width: {
                                                size: 50,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: ee.qte.toString(),
                                                        spacing: {
                                                        before: 50,
                                                        after: 50
                                                    },
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                                    ],
                                })

                        datrwEntre.push(
                        rentre
                        )

                        });
//pied:entrées:
                        let rent_peid = new TableRow({
                                    children: [


                                        new TableCell({
                                            width: {
                                                size: 100,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Total : "+item.data_entres.reduce((a, b) => +a + +b.qte, 0),
                                                        spacing: {
                                                        before: 50,
                                                        after: 50
                                                    },
                                                        heading: HeadingLevel.HEADING_5,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        columnSpan:2,
                                        }),

                                    ],
                                })


                        datrwEntre.push(
                        rent_peid
                        )

                        const tabEntre = new Table({

                            rows:  datrwEntre
                        })
//le Soties 
                        let datrwSorties =[new TableRow({
                                    children: [
                                        new TableCell({
                                            width: {
                                                size: 40,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Sortie",
                                                            spacing: {
                                                            before: 100,
                                                            after: 100
                                                        },
                                                        heading: HeadingLevel.HEADING_5,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                        new TableCell({
                                            width: {
                                                size: 20,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Qté Prod",
                                                            spacing: {
                                                            before: 100,
                                                            after: 100
                                                        },
                                                        heading: HeadingLevel.HEADING_5,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                        new TableCell({
                                            width: {
                                                size: 20,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Qté Stock",
                                                            spacing: {
                                                            before: 100,
                                                            after: 100
                                                        },
                                                        heading: HeadingLevel.HEADING_5,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                                                                new TableCell({
                                            width: {
                                                size: 20,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Lot",
                                                            spacing: {
                                                            before: 100,
                                                            after: 100
                                                        },
                                                        heading: HeadingLevel.HEADING_5,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                    ],
                                })]



                        item.data_sortie.forEach(function(ss){


                        let rsortie = new TableRow({
                                    children: [
                                        new TableCell({
                                            width: {
                                                size: 40,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: ss.nom_sortie,
                                                        spacing: {
                                                        before: 50,
                                                        after: 50
                                                    },
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                        new TableCell({
                                            width: {
                                                size: 20,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: ss.qte_prod.toString(),
                                                        spacing: {
                                                        before: 50,
                                                        after: 50
                                                    },
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                        new TableCell({
                                            width: {
                                                size: 20,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: ss.qte_stock.toString(),
                                                        spacing: {
                                                        before: 50,
                                                        after: 50
                                                    },
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                                                                new TableCell({
                                            width: {
                                                size: 20,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: ss.nom_lot,
                                                        spacing: {
                                                        before: 50,
                                                        after: 50
                                                    },
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                    ],
                                })

                        datrwSorties.push(
                        rsortie
                        )

                        });

//pied:entrées:
                        let sort_peid = new TableRow({
                                    children: [


                                        new TableCell({
                                            width: {
                                                size: 100,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Total Production : "+item.data_sortie.reduce((a, b) => +a + +b.qte_prod, 0),
                                                        spacing: {
                                                        before: 50,
                                                        after: 50
                                                    },
                                                        heading: HeadingLevel.HEADING_5,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        columnSpan:4,
                                        }),

                                    ],
                                })


                        datrwSorties.push(
                        sort_peid
                        )


                        const tabSorties = new Table({

                            rows:  datrwSorties
                        })

                        datrw =[new TableRow({
                                    children: [

                                        new TableCell({
                                            width: {
                                                size: 100,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({

                                            children: [

                                                new TextRun({
                                                text: item.nom,
                                                color: '636572',
                                                bold: false,
                                                font: "Segoe UI Light"
                                            })
                                            ],
                                                    spacing: {
                                                        before: 100,
                                                        after: 100
                                                    },
                                                        heading: HeadingLevel.HEADING_2,
                                                        alignment: AlignmentType.CENTER,
                                                    })],

                                            columnSpan:5,
                                            borders:{
                                                top:{style: BorderStyle.DOUBLE,size: 1,color: "585858"},
                                                bottom:{style: BorderStyle.DOUBLE,size: 1,color: "585858"},
                                                left:{style: BorderStyle.DOUBLE,size: 1,color: "585858"},
                                                right:{style: BorderStyle.DOUBLE,size: 1,color: "585858"},
                                            },
                                            

                                        }),



                                    ],
                                })]




                        let r = new TableRow({
                                    children: [
                                        new TableCell({
                                            width: {
                                                size: 40,
                                                type: WidthType.PERCENTAGE,
                                            },

                                            children: [
                                                tabEntre

                                            ],
                                            borders:{
                                                top:{style: BorderStyle.NONE,size: 1,color: "ff0000"},
                                                bottom:{style: BorderStyle.DOUBLE,size: 1,color: "585858"},
                                                left:{style: BorderStyle.DOUBLE,size: 1,color: "585858"},
                                                right:{style: BorderStyle.NONE,size: 1,color: "ff0000"},
                                            },

                                            
                                        }),

                                      new TableCell({
                                            width: {
                                                size: 60,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            children: [
                                                tabSorties

                                            ],
                                            borders:{
                                                top:{style: BorderStyle.NONE,size: 1,color: "ff0000"},
                                                bottom:{style: BorderStyle.DOUBLE,size: 1,color: "585858"},
                                                left:{style: BorderStyle.DOUBLE,size: 1,color: "585858"},
                                                right:{style: BorderStyle.DOUBLE,size: 1,color: "585858"},
                                            },

                                        }),
                                    ],
                                })

                        datrw.push(
                        r
                        )



                        
                        const tableRox = new Table({

                            rows:  datrw
                        })

//global Add:
                        let r_global = new TableRow({
                                    children: [
                                        new TableCell({
                                            width: {
                                                size: 11000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            borders:{
                                                top:{style: BorderStyle.NONE,size: 1,color: "ff0000"},
                                                bottom:{style: BorderStyle.NONE,size: 1,color: "ff0000"},
                                                left:{style: BorderStyle.NONE,size: 1,color: "ff0000"},
                                                right:{style: BorderStyle.NONE,size: 1,color: "ff0000"},
                                            },

                                            margins:{
                                                    top: 90,
                                                    bottom: 200,
                                                    left: 40,
                                                    right: 40
                                            },
                                            children: [
                                                tableRox
                                            ],
                                            columnSpan:5
                                        }),


                                    ],
                                })

                        datrwglobal.push(
                        r_global
                        )



                        });

                                const doc = new Document();

                        const table = new Table({

                            rows:  datrwglobal
                        })



                                doc.addSection({


                                    properties: {
                                        page: {
                                            pageNumbers: {
                                                start: 1,
                                                formatType: PageNumberFormat.DECIMAL,
                                            },
                                        },
                                    },
                                    headers: {
                                        default: new Header({
                                            children: [
                                                new Paragraph({
                                                    children: [
                                            new TextRun({
                                                text: "تعاونية حليب الريش",
                                                color: '636572',
                                                bold: true,

                                                })
                                                    ],
                                                border: {
                                                bottom: {
                                                        color: "636572",
                                                        space: 4,
                                                        value: "single",
                                                        size: 10,
                                                    }},
                                                    heading: HeadingLevel.TITLE,
                                                    alignment: AlignmentType.CENTER,
                                                }),
                                            ],
                                        }),
                                    },
                                    footers: {
                                        default: new Footer({
                                            children: [
                                                new Paragraph({
                                                    alignment: AlignmentType.RIGHT,
                                                    children: [
                                                        new TextRun({
                                                            children: ["Page : ", PageNumber.CURRENT],
                                                        }),
                                                        new TextRun({
                                                            children: [" Sur ", PageNumber.TOTAL_PAGES],
                                                        }),
                                                    ],
                                                }),
                                            ],
                                        }),
                                    },



                                    children: [

                                        new Paragraph({



                                            children: [

                                                new TextRun({
                                                text: "Production/ Transformation",
                                                color: '636572',
                                                bold: false,
                                                font: "Segoe UI Light"
                                            })
                                            ],

                                            heading: HeadingLevel.HEADING_1,
                                            alignment: AlignmentType.CENTER,

                                                spacing: {
                                                    after: 300,
                                                    before: 300
                                                },
                                        }),
                                        

                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "Nom : "+rowitem.nom+"          Date : "+rowitem.date,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }),

                            ],

                            heading: HeadingLevel.HEADING_2,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),


                                        table
                                        
                                        ]
                                })







                            Packer.toBlob(doc).then(blob => {

                        saveAs(blob, rowitem.nom+".docx");

                            });

                },



            afich_sorti_click(item) {

                                    this.$confirm("Voulez-vous vraiment Désarchiver cette production ??")
                                    .then(() => {



                                            this.isLoading=true;

                                            this.$http.get('/sortieValidProd/'+item.id+'/0')
                                                .then(response => {
                                                    //metre a jour id
                                                    console.log(response.data.data)

                                                    this.isLoading=false;
                                                    //afficher message
                                                    this.flashMessage.show({
                                                    status: 'success',
                                                    title: 'Données sauvegardées',
                                                    message: 'Les données sont sauvegardées correctement '

                                                })
                                                    //Get list of process
                                                    this.$router.push({ 
                                                        name: 'menuProduction'
                                                    });


                                                })
                                                .catch(error=> {console.log(error)
                                                this.isLoading=false;


                                            this.flashMessage.show({
                                                status: 'error',
                                                title: 'Erreur De Sauvegarde',
                                                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                                                })
                                                });












                                    })
                                    .catch(error=> {
                                    console.log(error)

                                    });




        },


        fetchList: function () {
                    this.isLoading=true;
                    this.$http.get('/prodictionType/3')
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },


        NewBon()
        {
                    this.$router.push({ 
                        name: 'menuProduction'
                    });
        },
    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>