<template>
  

<div  class="card rounded-lg shadow border border-white bg-white h-100 mb-0 pb-3 overflow-auto" >
    <div class="card-header bg-transparent">
             
           

<div class="row">


    <div class="col">
        <h5 class="ml-2 text-secondary font-weight-light"> <span class="fa fa-microchip"></span> Processus : {{nomProc}}<span class="ml-2 text-success"> </span> </h5>
    </div>

                <div class="col-5 p-0">

                <b-button pill   class="ml-0 mr-3 pt-1 pb-1 pr-2 pl-2 float-right" variant="outline-primary" @click="NewBon">
                            <i class="fa fa-home"></i>
                    <span> Précédent</span> 
                </b-button>


                        <b-button pill v-b-toggle.collap-categ.collap-articl  class=" ml-0 mr-3 pt-1 pb-1 pr-2 pl-2 float-right" variant="outline-primary">
                        <i class="fa fa-edit fa-1x"></i>     

                        <span> Afficher Les Entrées-sorties </span>           
                        </b-button> 
                </div>

                

</div>


    </div>




    <div class="row card-body h-100 p-0 m-0">

  
                <b-collapse id="collap-categ"   class="col-3 h-100 mr-0 ml-0 mt-2 p-0">

                            <div class="card border-success h-100 overflow-auto ml-2">


                            <b-list-group flush >
                            <b-list-group-item  v-for="item in listEntres" :key="item.id" button class="p-1" variant="success" @click="addEntre_click(item)" >
                                    {{ item.nom }} 
                            </b-list-group-item>

                            </b-list-group>
                            </div>



                    
                </b-collapse>

                <div class="col h-100 overflow-auto mt-2 mr-0 ml-0">
                            <div class="btn card border border-light shadow">


                                    <b-table 
                                    sort-icon-left
                                    small 
                                    responsive 

                                    :fields="fieldsEntres" 
                                    :items="listEntresProc"
                                    >

                                            <template #head(nom)="data">
                                                <span class="text-info font-weight-light">{{ data.label }}</span>
                                            </template> 

                                        <template #cell(actions)="row">
                                                <b-button pill size="sm"  class="mr-2 mt-1 mb-1 float-right" variant="outline-danger" @click="DellEntres_click(row.item)">
                                                            <i class="fa fa-trash-alt"></i>
                                                    
                                                </b-button>


                                        </template>
                                    
                                    </b-table>

                            </div>
                </div>

                <b-collapse id="collap-articl"   class="col-3 h-100 mr-0 mt-2 p-0">


                            <div class="card border-info h-100  overflow-auto">


                            <b-list-group flush  >
                       
                            <b-list-group-item  v-for="item in listSorties" :key="item.id" button class="p-1" variant="info" @click="addSorti_click(item)">
                                 {{ item.nom }}
                                 </b-list-group-item>

                          

                            </b-list-group>
                            </div>






                </b-collapse>

                <div class="col h-100 overflow-auto mt-2 mr-2 ml-0">



                            
                <div class="btn card border border-light shadow mt-0">



                        <b-table 
                        sort-icon-left
                        small 
                        responsive 

                        :fields="fieldsSorties" 
                        :items="listSortiesProc"
                        >
                            <template #head(nom)="data">
                                <span class="text-info font-weight-light">{{ data.label }}</span>
                            </template> 

                            <template #cell(actions)="rowAr">

                                    <b-button pill size="sm"  class="mr-2 mt-1 mb-1 float-right" variant="outline-danger" @click="DellSorties_click(rowAr.item.id)">
                                                <i class="fa fa-trash-alt"></i>
                                        
                                    </b-button>
                            </template>
                        
                        </b-table> 

                </div>


                       


                </div>

  </div>





      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

 </div>






  
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
 props: ['idProc','nomProc'],

 
    computed: {

    },
    mounted(){

            this.fetchListProc_entrees()
            this.fetchListProc_sorties()

            this.fetchEntresList()

    },
    data () {
		return {

            listEntres: [],
            listSorties: [],

            listEntresProc: [],
            listSortiesProc: [],

            fieldsEntres: [
                {key: 'nom', label: 'Les Entrées (Matières Premières)'},
                { key: 'actions', label: '' }
            ],

            fieldsSorties: [
                {key: 'nom', label: 'Les sorties (Produits)'},
                { key: 'actions', label: '' }
            ],


            ModalType:'Ajouter',


            isSubmitted: false,
            isLoading: false
		}
    },
    methods:{

        fetchEntresList: function () {
                    this.isLoading=true;
                    this.$http.get('/prodEntreActif')
                    .then((result) => {
                                this.listEntres = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });





                    this.isLoading=true;
                    this.$http.get('/prodSortiesActif')
                    .then((result) => {
                                this.listSorties = result.data.data
                                this.isLoading=false;

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },

        addEntre_click(item)
        {
                this.$confirm("Voulez-vous vraiment ajouter cet élément ??")
                 .then(() => {



            this.isLoading=true;

            this.$http.post('/prodProcEntres',{
            prod_proc_id:this.idProc,
            prod_entre_id:item.id

                })
                .then(response => {

                    this.isLoading=false;
                   // this.listEntresProc.unshift(response.data.data);
                    console.log(response.data.data)
                    //afficher message


                    this.fetchListProc_entrees();


                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });






                })
                .catch(error=> {
                console.log(error)

                });
        },

        addSorti_click(item) {

                this.$confirm("Voulez-vous vraiment ajouter cet élément ??")
                 .then(() => {



            this.isLoading=true;

            this.$http.post('/prodProcSorties',{
            prod_proc_id:this.idProc,
            prod_sorti_id:item.id

                })
                .then(response => {

                    this.isLoading=false;
                   // this.listEntresProc.unshift(response.data.data);
                    console.log(response.data.data)

                    this.fetchListProc_sorties()

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });






                })
                .catch(error=> {
                console.log(error)

                });


        },




        DellSorties_click(id_art)
        {
                this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                 .then(() => {

            this.isLoading=true;

            this.$http.delete('/prodProcSorties/'+id_art)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })
                    let index = this.listSortiesProc.findIndex(item => item.id === id_art)

                    this.listSortiesProc.splice(index, 1)
                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });






                })
                .catch(error=> {
                console.log(error)

                });
        },

        DellEntres_click(ite)
        {
                this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                 .then(() => {

            this.isLoading=true;

            this.$http.delete('/prodProcEntres/'+ite.id)
                .then(response => {
                console.log(response.data)

                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })
                    let index = this.listEntresProc.findIndex(item => item.id === ite.id)

                    this.listEntresProc.splice(index, 1)
                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });






                })
                .catch(error=> {
                console.log(error)

                });
        },


        fetchListProc_entrees()
        {
                    this.isLoading=true;
                    this.$http.get('/entresParProces/'+this.idProc)
                    .then((result) => {
                                this.listEntresProc = result.data.data

                                this.isLoading=false;

                                //effacer le search:
                                this.serchArt=''
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });



        },

 
        fetchListProc_sorties()
        {
                    this.isLoading=true;
                    this.$http.get('/sortieParProces/'+this.idProc)
                    .then((result) => {
                                this.listSortiesProc = result.data.data

                                this.isLoading=false;

                                //effacer le search:
                                this.serchArt=''
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });



        },
        NewBon()
        {
                    this.$router.push({ 
                        name: 'prodProcesList'

                    });

        }


        },
        components: {
                    Loading      
        }


}
</script>

<style>
/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #9cc7f9;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #9cc7f9;
}
::-webkit-scrollbar-thumb:active {
  background: #9cc7f9;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 60px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

</style>