<template>
<div>
  
                        
      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center ">    

                <h4 class="col-4 ml-3 font-weight-lighter text-info"> <span class="fa fa-boxes"></span> Production (Les Sorties)    </h4> 
                
                <div class="col m-1">
                    
                                <b-button pill size="sm"  class="m-2 float-right" variant="info" @click="fetchList">
                                    <i class="fa fa-sync-alt"></i>
                                    <span> Actualiser</span> 
                                </b-button>
                                <b-button pill size="sm"  class="m-2 float-right" variant="success" @click="showProdPert">
                                            <i class="fa fa-plus-square"></i>
                                    <span> Perte</span> 
                                </b-button>

                                <b-button pill size="sm"  class="m-2 float-right" variant="success" @click="fetchFormListEntrees">
                                            <i class="fa fa-plus-square"></i>
                                    <span> Matière Première</span> 
                                </b-button>

                                <b-button pill size="sm"  class="m-2 float-right" variant="success" @click="showProdFini">
                                            <i class="fa fa-plus-square"></i>
                                    <span> Produit Fini</span> 
                                </b-button>

                </div>
            </div>
       



        <b-table 
        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        >
            <template #cell(actions)="row">
                    <b-button pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="del_click(row.item)">
                                <i class="fa fa-trash-alt"></i>
                        
                    </b-button>
                    <b-button pill size="sm" class="mr-2 float-right" variant="outline-primary" @click="edit_click(row.item)">
                            <i class="fa fa-pencil-alt"></i>
                    
                    </b-button>
                </template>
        
        </b-table>
      </div>


    <!-- le modal -->
    <b-modal id="banqModal" :title="itemForm.nom"  hide-footer>
           <form @submit.prevent="handleSubmit">


               
            <div class="form-group">
                <label for="name">Nom :</label>
                <input type="text" v-model="itemForm.nom" id="name" name="name" class="form-control"
                :class="{ 'is-invalid': isSubmitted && $v.itemForm.nom.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.nom.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>



            <div  class="form-group">
                        <div class="form-group">
                            <label for="centre">Entrée (Production)</label>
                            <select class="form-control" id="centre" v-model="itemForm.article_entr_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemForm.article_entr_id.$error }">
                            <option v-for="item in list_articles" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>

                            </select>
                        </div>

                            <div v-if="isSubmitted && !$v.itemForm.article_entr_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>



            <div class="form-group">
                <label for="unite_stock">Unité :</label>
                <input type="text" v-model="itemForm.unite_stock" id="unite_stock" name="unite_stock" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.unite_stock.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.unite_stock.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>




            <div class="custom-control custom-switch">
            <input type="checkbox"
             class="custom-control-input" 
             id="etat"
                    v-model="itemForm.etat"
                    name="etat"
                    value=true
                    unchecked-value=false

             >

            <label class="custom-control-label" for="etat"> Actif</label>
            </div>

            <div class="form-group mt-4">
                <button type="submit" class="btn  btn-primary btn-block">{{ModalType}}</button>
            </div>


        </form>
    </b-modal>


    <!-- le modal -->
    <b-modal id="banqModalFini" :title="itemForm.nom"  hide-footer>
           <form @submit.prevent="handleSubmitfini">


               
            <div class="form-group">
                <label for="name">Nom :</label>
                <input type="text" v-model="itemForm.nom" id="name" name="name" class="form-control"
                :class="{ 'is-invalid': isSubmitted && $v.itemForm.nom.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.nom.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>



            <div v-if="d_get_gst_prod_stock"  class="form-group">
                        <div class="form-group">
                            <label for="centre">Article :</label>
                            <select class="form-control" id="centre" v-model="itemForm.article_entr_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemForm.article_entr_id.$error }">
                            <option v-for="item in list_entrees" :key="item.id" v-bind:value="item.id">   
                                {{ item.ref }}
                            </option>

                            </select>
                        </div>

                            <div v-if="isSubmitted && !$v.itemForm.article_entr_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>



            <div class="form-group">
                <label for="unite_prod">Unité De Production:</label>
                <input type="text" v-model="itemForm.unite_prod" id="unite_prod" name="unite_prod" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.unite_prod.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.unite_prod.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>


            <div class="form-group">
                <label for="unite_stock">Unité De Stockage :</label>
                <input type="text" v-model="itemForm.unite_stock" id="unite_stock" name="unite_stock" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.unite_stock.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.unite_stock.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>

            <div class="form-group">
                <label for="prod_to_stock">U.Production AU  U.Stockage :</label>
                <input type="text" v-model="itemForm.prod_to_stock" id="prod_to_stock" name="prod_to_stock" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.prod_to_stock.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.prod_to_stock.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.prod_to_stock.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>

            <div class="custom-control custom-switch">
            <input type="checkbox"
             class="custom-control-input" 
             id="etat"
                    v-model="itemForm.etat"
                    name="etat"
                    value=true
                    unchecked-value=false

             >

            <label class="custom-control-label" for="etat"> Actif</label>
            </div>

            <div class="form-group mt-4">
                <button type="submit" class="btn  btn-primary btn-block">{{ModalType}}</button>
            </div>


        </form>
    </b-modal>


    <!-- les trucs public -->

    <!-- le modal -->
    <b-modal id="perteModal" :title="itemForm.nom"  hide-footer>
           <form @submit.prevent="handleSubmitpert">


               
            <div class="form-group">
                <label for="name">Nom :</label>
                <input type="text" v-model="itemForm.nom" id="name" name="name" class="form-control"
                :class="{ 'is-invalid': isSubmitted && $v.itemForm.nom.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.nom.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>




            <div class="form-group">
                <label for="unite_stock">Unité :</label>
                <input type="text" v-model="itemForm.unite_stock" id="unite_stock" name="unite_stock" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.unite_stock.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.unite_stock.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>




            <div class="custom-control custom-switch">
            <input type="checkbox"
             class="custom-control-input" 
             id="etat"
                    v-model="itemForm.etat"
                    name="etat"
                    value=true
                    unchecked-value=false

             >

            <label class="custom-control-label" for="etat"> Actif</label>
            </div>

            <div class="form-group mt-4">
                <button type="submit" class="btn  btn-primary btn-block">{{ModalType}}</button>
            </div>


        </form>
    </b-modal>






    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";

export default {
    computed: {
    d_get_gst_prod_stock() {
    return this.$store.getters.get_gst_prod_stock;
    },

    },
    mounted(){
       this.fetchList()
    },
	data () {
		return {
            list_entrees:[],
            list_articles:[],
            itemslist: [],
            itemForm: {
                    id:'',
                    nom:'',
                    is_fini:'',
                    article_entr_id:'',
                    unite_prod:'',
                    unite_stock:'',
                    prod_to_stock:'',
                    etat:''},
            fields: [
				{key: 'nom', label: 'Nom', sortable: true},
                {key: 'is_fini', label: 'Produit Fini', 
                formatter: (value) => {
                 return value ? 'Oui' : 'Non'
                }, sortable: true},
                {key: 'unite_prod', label: 'Unité Production'},
                {key: 'unite_stock', label: 'Unité Stockage'},
                {key: 'prod_to_stock', label: 'U.Prod AU U.Stock'},
                {key: 'etat', label: 'Actif', 
                formatter: (value) => {
                 return value ? 'Oui' : 'Non'
                }, sortable: true},
                { key: 'actions', label: '' }
            ],
            //public trucs
            ModalEdit: false,
            ModalType:'Ajouter',
            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            itemForm: {
                nom: {
                    required
                },
                is_fini: {
                    required
                },
                article_entr_id: {
                    required
                },
                unite_prod: {
                    required
                },
                unite_stock: {
                    required
                },
                prod_to_stock: {
                    required,
                    decimal
                },
                etat: {
                    required
                }
            }
    },
    methods :{
        showProdPert()
        {
                                //le modal
                                this.itemForm.id=''
                                this.itemForm.is_fini=false,
                                this.itemForm.nom='',
                                this.itemForm.article_entr_id=0,
                                this.itemForm.unite_prod='',
                                this.itemForm.unite_stock=''
                                this.itemForm.prod_to_stock=1,
                                this.itemForm.etat=true,

                                this.ModalEdit=false;
                                this.ModalType='Ajouter'

                                this.$root.$emit('bv::show::modal', 'perteModal')
        },
        showProdFini()
        {
                if(this.d_get_gst_prod_stock==false)
                {
                    //le modal
                                //le modal
                                this.itemForm.id=''
                                this.itemForm.is_fini=false,
                                this.itemForm.nom='',
                                this.itemForm.article_entr_id=0,
                                this.itemForm.unite_prod='',
                                this.itemForm.unite_stock=''
                                this.itemForm.prod_to_stock=1,
                                this.itemForm.etat=true,

                                this.ModalEdit=false;
                                this.ModalType='Ajouter'

                                this.$root.$emit('bv::show::modal', 'banqModalFini')

                }
                else
                {
                    this.fetchFormListArticles()
                }
        },

        fetchFormListEntrees: function () {
                    //chager les prodEntres:
                    this.isLoading=true;
                    this.$http.get('/prodEntres')
                    .then((result) => {
                                this.list_articles = result.data.data
                                this.isLoading=false;




                                            //le modal
                                this.itemForm.id=''
                                this.itemForm.is_fini=false
                                this.itemForm.nom='',
                                this.itemForm.article_entr_id='',
                                this.itemForm.unite_prod='',
                                this.itemForm.unite_stock=''
                                this.itemForm.prod_to_stock='',
                                this.itemForm.etat=true,

                                this.ModalEdit=false;
                                this.ModalType='Ajouter'

                                this.$root.$emit('bv::show::modal', 'banqModal')




                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },


        fetchFormListArticles: function () {
                    //chager les prodEntres:
                    this.isLoading=true;
                    this.$http.get('/articlesParTypeCateg/2')
                    .then((result) => {
                                this.list_entrees = result.data.data
                                this.isLoading=false;

                                //le modal
                                this.itemForm.id=''
                                this.itemForm.is_fini=false,
                                this.itemForm.nom='',
                                this.itemForm.article_entr_id='',
                                this.itemForm.unite_prod='',
                                this.itemForm.unite_stock=''
                                this.itemForm.prod_to_stock=1,
                                this.itemForm.etat=true,

                                this.ModalEdit=false;
                                this.ModalType='Ajouter'

                                this.$root.$emit('bv::show::modal', 'banqModalFini')




                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },


        itemslisttore(){
            this.isLoading=true;

            this.$http.post('/prodSorties',{
            nom:this.itemForm.nom,
            is_fini:this.itemForm.is_fini,
            article_entr_id:this.itemForm.article_entr_id,
            unite_prod:this.itemForm.unite_prod,
            unite_stock:this.itemForm.unite_stock,
            prod_to_stock:this.itemForm.prod_to_stock,
            etat:this.itemForm.etat

                })
                .then(response => {

                    this.isLoading=false;
                    this.itemslist.unshift(response.data.data);


                    this.$bvModal.hide('banqModal')
                    this.$bvModal.hide('banqModalFini')
                    this.$bvModal.hide('perteModal')


                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


               this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },
        banqueEdit(){

            this.isLoading=true;

            this.$http.patch('/prodSorties/'+this.itemForm.id,{
            nom:this.itemForm.nom,
            is_fini:this.itemForm.is_fini,
            article_entr_id:this.itemForm.article_entr_id,
            unite_prod:this.itemForm.unite_prod,
            unite_stock:this.itemForm.unite_stock,
            prod_to_stock:this.itemForm.prod_to_stock,
            etat:this.itemForm.etat

                })
                .then(response => {

                    this.isLoading=false;
                    console.log(response.data)
                    this.$bvModal.hide('banqModal')
                    this.$bvModal.hide('banqModalFini')
                    this.$bvModal.hide('perteModal')

                    let index = this.itemslist.findIndex(item => item.id === this.itemForm.id)
                    this.itemslist.splice(index, 1,response.data.data)
                    
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données modifiées',
                    message: 'Les données sont modifiées correctement'
                    })


                    })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;


                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Modification',
                    message: 'Impossible  de modifier les données .. Réessayez !!'
                })
                });

        },
        banqueDell(){

            this.isLoading=true;

            this.$http.delete('/prodSorties/'+this.itemForm.id)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                    let index = this.itemslist.findIndex(item => item.id === this.itemForm.id)
                    this.itemslist.splice(index, 1)




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },

    edit_click(item) {

                this.$confirm("Voulez-vous vraiment modifier cet élément ??")
                 .then(() => {


                if(item.is_fini==1)
                {
                    if(this.d_get_gst_prod_stock==false)
                    {
                                   this.itemForm.id=item.id
                                    this.itemForm.nom=item.nom
                                    this.itemForm.is_fini=item.is_fini,
                                    this.itemForm.article_entr_id=item.article_entr_id,
                                    this.itemForm.unite_prod=item.unite_prod,
                                    this.itemForm.unite_stock=item.unite_stock,
                                    this.itemForm.prod_to_stock=item.prod_to_stock,
                                    this.itemForm.etat=item.etat

                                    this.ModalEdit=true;
                                    this.ModalType='Modifier'




                                this.$root.$emit('bv::show::modal', 'banqModalFini')
                    }
                    else
                    {
                                                                //chager les prodEntres:
                                this.isLoading=true;
                                this.$http.get('/synchArticles')
                                .then((result) => {
                                            this.list_entrees = result.data.data
                                            this.isLoading=false;


                                                this.itemForm.id=item.id
                                                this.itemForm.nom=item.nom
                                                this.itemForm.is_fini=item.is_fini,
                                                this.itemForm.article_entr_id=item.article_entr_id,
                                                this.itemForm.unite_prod=item.unite_prod,
                                                this.itemForm.unite_stock=item.unite_stock,
                                                this.itemForm.prod_to_stock=item.prod_to_stock,
                                                this.itemForm.etat=item.etat

                                                this.ModalEdit=true;
                                                this.ModalType='Modifier'




                                            this.$root.$emit('bv::show::modal', 'banqModalFini')
                                })
                                .catch(error=> {
                                            console.log(error)
                                            this.isLoading=false;
                                            this.flashMessage.show({
                                            status: 'error',
                                            title: 'Erreur de Chargement',
                                            message: 'Impossible charger les données.. Actualisez et Réessayez'
                                            })
                                });

                    }


                }
                else
                {
                                    if(item.article_entr_id==0)
                                    {

                                                        this.itemForm.id=item.id
                                                        this.itemForm.nom=item.nom
                                                        this.itemForm.is_fini=item.is_fini,
                                                        this.itemForm.article_entr_id=item.article_entr_id,
                                                        this.itemForm.unite_prod=item.unite_prod,
                                                        this.itemForm.unite_stock=item.unite_stock,
                                                        this.itemForm.prod_to_stock=item.prod_to_stock,
                                                        this.itemForm.etat=item.etat

                                                        this.ModalEdit=true;
                                                        this.ModalType='Modifier'


                                                        this.$root.$emit('bv::show::modal', 'perteModal')
                                    }
                                    else
                                    {
                                                                                    //chager les prodEntres:
                                            this.isLoading=true;
                                            this.$http.get('/prodEntres')
                                            .then((result) => {
                                                        this.list_articles = result.data.data
                                                        this.isLoading=false;



                                                        this.itemForm.id=item.id
                                                        this.itemForm.nom=item.nom
                                                        this.itemForm.is_fini=item.is_fini,
                                                        this.itemForm.article_entr_id=item.article_entr_id,
                                                        this.itemForm.unite_prod=item.unite_prod,
                                                        this.itemForm.unite_stock=item.unite_stock,
                                                        this.itemForm.prod_to_stock=item.prod_to_stock,
                                                        this.itemForm.etat=item.etat

                                                        this.ModalEdit=true;
                                                        this.ModalType='Modifier'


                                                        this.$root.$emit('bv::show::modal', 'banqModal')
                                            })
                                            .catch(error=> {
                                                        console.log(error)
                                                        this.isLoading=false;
                                                        this.flashMessage.show({
                                                        status: 'error',
                                                        title: 'Erreur de Chargement',
                                                        message: 'Impossible charger les données.. Actualisez et Réessayez'
                                                        })
                                            });
                                    }



                }

                })
                .catch(error=> {
                console.log(error)

                });


      },
        del_click(item) {

                this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                 .then(() => {
                this.itemForm.id=item.id

                                    this.banqueDell()
                                })
                                .catch(error=> {
                                console.log(error)

                                    });




      },
        fetchList: function () {
                    this.isLoading=true;
                    this.$http.get('/prodSorties')
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });






        },

        handleSubmit() {
                //traitement:

                        this.itemForm.is_fini=0
                        this.itemForm.unite_prod=this.itemForm.unite_stock,
                        this.itemForm.prod_to_stock=1,

                //fin traitement

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {
                            if(this.ModalEdit)
                            {
                                this.banqueEdit()
                            }
                            else
                            {

                                this.itemslisttore()
                            }   

                }

            },
            handleSubmitfini() {

                  /*  if(this.d_get_gst_prod_stock==false)
                    {
                        this.itemForm.article_entr_id= 0
                    }*/


                //traitement:
                        this.itemForm.is_fini=1
                //fin traitement

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {
                            if(this.ModalEdit)
                            {
                                this.banqueEdit()
                            }
                            else
                            {

                                this.itemslisttore()
                            }   

                }

            },
            handleSubmitpert() {
                //traitement:

                        this.itemForm.is_fini=0
                        this.itemForm.unite_prod=this.itemForm.unite_stock,
                        this.itemForm.prod_to_stock=1,

                //fin traitement

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {
                            if(this.ModalEdit)
                            {
                                this.banqueEdit()
                            }
                            else
                            {

                                this.itemslisttore()
                            }   

                }

            },

    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>