<template>
  
<div  class="card  shadow border border-white bg-white h-100 pb-3 overflow-auto">


        <div class="row pb-1 border-bottom border-info m-3">


                <h4 class="font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Production</h4> 

                <div class="col">

                                    <b-button pill size="sm"  class="m-1 float-right text-secondary" variant="link" @click="NewBon">
                                                <i class="fa fa-home fa-2x"></i>
                                    </b-button>

                                    <b-button v-if="nom_dis" pill size="sm"  class="m-2 float-right" variant="success" @click="terminer_click">
                                        <i class="fa fa-check-square"></i>
                                        <span> Terminer</span> 
                                    </b-button>
                </div>
            
        </div>

          <div class="row m-5">

        <div class="col-md-12 ml-auto mr-auto">

            <div class="row">


                            <div v-if="!nom_dis"  class="col">
                                <form @submit.prevent="handleSubmitBonCMD()">

                                    <div class="form-group">
                                        <label for="qte">Nom :</label>
                                        <input type="text" v-model="itemFormBon.nom" id="qte" name="qte" class="form-control"
                                        :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.nom.$error }"/>
                                        
                                        <div v-if="isSubmitted && !$v.itemFormBon.nom.required" class="invalid-feedback">Ce champ est obligatoire !!</div>


                                    </div>


                                    <div class="form-group">
                                        <label for="date_exp" class="mr-sm-2">Date :</label>
                                            <b-form-datepicker
                                            class="mr-sm-2"
                                            id="datepicker-date_exp"
                                            placeholder="date"
                                            v-model="itemFormBon.date"
                                            :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                                            locale="fr" />
                                    </div>




                                    <div class="form-group mt-2">
                                    <label for="adresse">Description :</label>
                                    <textarea name="adresse" id="adresse"  rows="2" class="form-control" v-model="itemFormBon.description"></textarea>
                                    </div>


                                    <div v-if="!nom_dis" class="form-group">
                                        <button type="submit" class="btn  btn-primary btn-block">Suivant</button>
                                    </div>

                                </form>
                            </div> 

                            <div v-if="nom_dis"  class="col">
                                    <div class="row">

                                                    <div  class="col h-100 mr-0 ml-0 mt-2 p-0">

                                                                <div class="card border-success h-100 overflow-auto ml-2">


                                                                <b-list-group flush >
                                                                <b-list-group-item  v-for="item in listOfProcess" :key="item.id" button class="p-3" variant="success" @click="addProc_click(item)" >
                                                                       <span class="fa fa-microchip"></span>
                                                                        {{ item.nom }} 
                                                                </b-list-group-item>

                                                                </b-list-group>
                                                                </div>



                                                        
                                                    </div>

                                                    <div class="col h-100 overflow-auto mt-2 mr-0 ml-0">
                                                                <div class="btn card border border-light shadow">

                                                                        <b-table 
                                                                        sort-icon-left
                                                                        small 
                                                                        responsive 

                                                                        :fields="fieldsProc" 
                                                                        :items="listSelectedProc"
                                                                        >

                                                                        <template #head(nom_processus)="data">
                                                                            <span class="text-info font-weight-light">{{ data.label }}</span>
                                                                        </template> 
                                                                        
                                                                            <template #cell(actions)="row">
                                                                                    <b-button pill size="sm"  class="mr-2 mt-1 mb-1 float-right" variant="outline-danger" @click="DellProc_click(row.item)">
                                                                                                <i class="fa fa-trash-alt"></i>
                                                                                        
                                                                                    </b-button>


                                                                            </template>
                                                                        
                                                                        </b-table>

                                                                </div>

                                                    </div>




                                    </div>

                            </div> 
            </div>   

        </div>   

</div> 
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>
</div>






  
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";

export default {
 props: ['ResivedId'],

 computed: {
    user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    }

  },
mounted(){



if(this.ResivedId)
{
this.process_modification(this.ResivedId)
}
else
{
this.process_Ajouter()
}

    },
data () {
		return {


            listOfProcess: [],
            listSelectedProc: [],

            fieldsProc: [
                {key: 'nom_processus', label: 'Les Processus'},
                { key: 'actions', label: '' }
            ],

            itemFormBon: {
                    id:'',
                    nom:"Production : "+new Date().toJSON().slice(0,10).replace(/-/g,'-') ,
                    date:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    description:'',
                    etat:0,
                    user:'user'},

            isSubmitted: false,
            isLoading: false,
            isModification:false,


            //boninfos Champs disibl:
            nom_dis:false,
        


		}
    },
    validations: {
            itemFormBon: {
                nom: {
                    required
                },
                date: {
                    required
                },
                etat: {
                    required
                },
                user: {
                    required
                }
            }

    },
methods:{


        process_modification(id_resived)
        {


                    this.isLoading=true;
                    this.$http.get('/prodProductions/'+id_resived)
                    .then((result) => {
                    
                    //affectation des information
                    this.itemFormBon.id=result.data.data.id,
                    this.itemFormBon.nom=result.data.data.nom,
                    this.itemFormBon.date=result.data.data.date,
                    this.itemFormBon.description=result.data.data.description,
                    this.itemFormBon.etat=result.data.data.etat,
                    this.itemFormBon.user=result.data.data.user




                    //boninfos Champs disibl:
                    this.nom_dis=false,
                     
                     //Activer la modification
                     this.isModification=true,


                     //desible load
                     this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },
        process_Ajouter()
        {

                    //boninfos Champs disibl:
                    this.nom_dis=false,

                    //Activer la modification
                    this.isModification=false

        },

        handleSubmitBonCMD(){


                //USER HANDLE
                this.itemFormBon.user= this.user_name

                
                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                    if(this.isModification)
                    {
                
                    this.edit_bon()
                    }
                    else
                    {
                    
                    this.add_bon()
                    }
   

                }


        },
        add_bon()
        {

            this.isLoading=true;

            this.$http.post('/prodProductions',{

                    nom:this.itemFormBon.nom,
                    date:this.itemFormBon.date,
                    description:this.itemFormBon.description,
                    etat:this.itemFormBon.etat,
                    user:this.itemFormBon.user

                })
                .then(response => {
                    //metre a jour id
                    this.itemFormBon.id=response.data.data.id,
                    this.itemFormBon.nom=response.data.data.nom,
                    //boninfos Champs disibl:
                    this.nom_dis=true,
          
                    this.isLoading=false;
                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '


                })
                    //Get list of process

                    this.fetchProcList()
                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


               this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });


        },
        edit_bon()
        {

            this.isLoading=true;

            this.$http.patch('/prodProductions/'+this.itemFormBon.id,{
                    nom:this.itemFormBon.nom,
                    date:this.itemFormBon.date,
                    description:this.itemFormBon.description,
                    etat:this.itemFormBon.etat,
                    user:this.itemFormBon.user
                })
                .then(response => {


                    //metre a jour id
                    this.itemFormBon.id=response.data.data.id,
                    this.itemFormBon.nom=response.data.data.nom,

                    //boninfos Champs disibl:
                    this.nom_dis=true,

                    this.isLoading=false;

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })

                    //Get list of process
                    this.fetchSelectedProcList()
                    this.fetchProcList()
                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


               this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });


        },


        fetchProcList: function () {
                    this.isLoading=true;
                    this.$http.get('/processActif')
                    .then((result) => {
                                this.listOfProcess = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },


        fetchSelectedProcList: function () {
                    this.isLoading=true;
                    this.$http.get('/detailProcessParProd/'+this.itemFormBon.id)
                    .then((result) => {
                                this.listSelectedProc = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },

        addProc_click(item)
        {
                this.$confirm("Voulez-vous vraiment ajouter cet élément ??")
                 .then(() => {



            this.isLoading=true;

            this.$http.post('/prodProducDetails',{
            prod_proc_id:item.id,
            prod_production_id:this.itemFormBon.id,
            nom_processus:item.nom

                })
                .then(response => {

                    this.isLoading=false;
                    this.listSelectedProc.unshift(response.data.data);

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });






                })
                .catch(error=> {
                console.log(error)

                });
        },


        DellProc_click(ite)
        {
                this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                 .then(() => {

            this.isLoading=true;

            this.$http.delete('/prodProducDetails/'+ite.id)
                .then(response => {
                console.log(response.data)

                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })
                    let index = this.listSelectedProc.findIndex(item => item.id === ite.id)

                    this.listSelectedProc.splice(index, 1)
                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });






                })
                .catch(error=> {
                console.log(error)

                });
        },
        terminer_click()
        {
            
            this.isLoading=true;

            this.$http.get('/productionInitEntrees/'+this.itemFormBon.id)
                .then(response => {
                    //metre a jour id
                    console.log(response.data.data)

                    this.isLoading=false;
                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '


                })
                    //Get list of process

                                            this.$router.push({ 
                                            name: 'productionEntreesProd', 
                                            params: { 
                                            idProd: this.itemFormBon.id,
                                            nomProd: this.itemFormBon.nom,
                                            dateProd: this.itemFormBon.date
                                            } 
                            });
                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


               this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },
        NewBon()
        {
                //Get list of process
                this.$router.push({ 
                    name: 'menuProduction'
                });
        }

},
components: {
              Loading      
}


}
</script>

<style>

</style>