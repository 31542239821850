<template>
<div class="p-4 back_princ">



<div> <!-- partie plateaux --> 



<div class=" d-flex justify-content-center text-info">
 <i class="fas fa-microchip fa-4x"></i>
</div>

<div class=" d-flex justify-content-center text-info mb-4">
 <h2 class="font-weight-lighter">Production ( Processus )</h2>
</div>



<div class="row m-2 pt-4">

            <!-- Earnings (Monthly) Card Example --> 
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_entres">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary text-uppercase">Entrées (Matières premières)</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-box-open fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

                        <!-- Earnings (Monthly) Card Example --> 
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_sorties">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary text-uppercase"> Sorties ( Produits )</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-boxes fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>


</div>

<div class=" row m-2 pt-4">



<!-- Earnings (Monthly) Card Example --> 
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_list_proc">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary text-uppercase"> Liste Des Processus</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-microchip fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
</div>


</div> <!-- Fin partie plateaux --> 









  </div>
</template>

<script>

export default {
  
    computed: {

    },
data () {
		return {





		}
    },
 methods:{

      click_entres()
      {
                        //goto first page:
                    this.$router.push({ 
                        name: 'prodEntrees'

                    });
      },
      click_sorties()
      {
                        //goto first page:
                    this.$router.push({ 
                        name: 'prodSorties'

                    });
      },

      click_list_proc()
      {
                                           //goto first page:
                    this.$router.push({ 
                        name: 'prodProcesList'

                    });

      }


 },
}
</script>
<style scoped>
.back_princ{
  background: #eff7fa;
}

.text_org{
  color: green;
}
</style>