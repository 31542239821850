<template>
<div class="p-4 back_princ">

<div class=" d-flex justify-content-center text-info">
 <i class="fas fa-file-alt fa-4x"></i>
</div>

<div class=" d-flex justify-content-center text-info mb-4">
 <h2 class="font-weight-lighter">Bons De Réceptions / Sorties</h2>
</div>




<div class=" row p-4">

            <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_new">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary text-uppercase">Nouveaux</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-plus-square fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_brouillon">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="font-weight-bold text-secondary  text-uppercase ">Liste Des Bons <span class="text-warning">( Brouillon ) </span></div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-edit fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>


</div>





<div class=" row p-4">

            <!-- Earnings (Annual) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2"  @click="click_valid">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary  text-uppercase">Liste Des Bons De Réceptions <span class="text-success">( Validés ) </span></div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-check-square fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>


                        <!-- Earnings (Annual) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2"  @click="click_valid_sort">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary  text-uppercase">Liste Des Bons De Sorties <span class="text-success">( Validés ) </span></div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-check-square fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>


</div>

    <!-- le modal -->
    <b-modal id="casseModal"  hide-footer hide-header>


<div class="row">
                    <b-button  pill   class="m-4 p-2 btn-block" variant="outline-success" @click="btnReception_click">
                        <i class="fa fa-truck-moving fa-2x mr-2"></i>
                        Bon De Réception
                    </b-button>

                    <b-button  pill  class="mt-4 ml-4 mr-4 mb-3 p-2 btn-block btn-block" variant="outline-secondary" @click="btnSorties_click">
                        <i class="fa fa-dolly-flatbed fa-2x mr-2"></i>
                        Bon De Sorties
                    </b-button>
</div>



    </b-modal>


  </div>
</template>

<script>
export default {

 methods:{

      btnReception_click()
      {
                      //goto first page:
                    this.$router.push({ 
                        name: 'prodReception',
                        params: { 
                        recp: true
                        } 

                    });
      },
      btnSorties_click()
      {
                              //goto first page:
                    this.$router.push({ 
                        name: 'prodReception',
                        params: { 
                        recp: false
                        } 

                    });
      },
       click_new()
      {
 this.$root.$emit('bv::show::modal', 'casseModal')
      },
      click_brouillon()
      {
                       //goto first page:
                    this.$router.push({ 
                        name: 'listReception'

                    });
      },
      click_valid()
      {
                        //goto first page:
                    this.$router.push({ 
                        name: 'listReceptionVal'

                    });
      },
      click_valid_sort()
      {
                        //goto first page:
                    this.$router.push({ 
                        name: 'listReceptionSortVal'

                    });
      },




 }
}
</script>
<style scoped>
.back_princ{
  background: #eff7fa;
}

.text_org{
  color: green;
}
</style>