<template>
<div>


        <div class="row">
            <div class="col">



      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    



                    <div class="col">
                        
                        <h4 class="ml-3 mt-2 font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Productions (Entrées) </h4> 

                        <div class="mt-1 ml-2  text-secondary">    - Production : <span class="text-info"> {{nomProd}} </span>  </div>
                        <div class="mt-1 ml-2 text-secondary">    - Date : <span class="text-info"> {{dateProd}} </span>  </div>
                        <div class="mt-1 ml-2 text-secondary">    
                            - Processus : <span class="text-primary"> {{proc_filter}} </span>  
                            <b-button @click="filter_click(1)"    class="ml-1 mr-1 p-1 text-decoration-none" variant="link">
                            <i class="fa fa-filter"></i>                
                            </b-button> 

                            <b-button @click="filter_click(0)"    class="mr-1 p-1 text-secondary" variant="link">
                            <i class="fa fa-eraser"></i>                
                            </b-button> 
                        </div>

                    </div>


                    <div class="col">

                                    <b-button pill size="sm"  class="m-1 float-right text-secondary" variant="link" @click="NewBon">
                                                <i class="fa fa-home fa-2x"></i>
                                    </b-button>


                                    <b-button pill size="sm"  class="m-2 float-right" variant="outline-info" @click="fetchList">
                                        <i class="fa fa-sync-alt"></i>
                                        <span> Actu.</span> 
                                    </b-button>

                                    <b-button pill size="sm"  class="m-2 float-right" variant="outline-secondary" @click="fetchListSortie">
                                        <i class="fa fa-file-alt"></i>
                                        <span> Sorties</span> 
                                    </b-button>

                                    <b-button pill size="sm"  class="m-2 float-right" variant="success" @click="terminer_click">
                                        <i class="fa fa-check-square"></i>
                                        <span> Valider</span> 
                                    </b-button>
                    </div>
            </div>
       


        <b-table 
        id="main-table"
        :per-page="perPage"
        :current-page="currentPage"

        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="filterList"
        >
            <template #cell(actions)="row">

                    <b-button pill size="sm" class="mr-2 float-right" variant="outline-secondary" @click="fetchListArts(row.item)">
                            <i class="fa fa-highlighter"></i>
                    </b-button>
                </template>
        
        </b-table>
                <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>



      </div>
                

            </div>

            <div class="ml-2 col-3">
                <div class="rounded-lg shadow border border-white bg-white">

                    <div class="mt-4 d-flex justify-content-center text-secondary">
                    <i class="fas fa-warehouse fa-3x"></i>
                    </div>

                    <div class=" d-flex justify-content-center text-secondary mb-2">
                    <h4 class="mt-2 font-weight-lighter">{{itemForm.nom_entre}}</h4> 
                    </div>


       
            <form @submit.prevent="handleSubmit" >
          
            <div class="form-group m-3">
                <label for="qte_min" class="form-control-sm mb-0">Quantité : </label>
                <input ref="qte_field" type="text" v-model="itemForm.qte" id="qte_min" name="qte_min" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.qte.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.qte.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.qte.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>




            <div v-if="itemForm.id" class="form-group m-3">
                <button type="submit" class="btn btn-secondary btn-block">Valider</button>
            </div>





            </form>



                                 </div>
            </div>
        </div>



    <!-- le modal -->
    <b-modal id="filterModal"  hide-footer hide-header>



            <div class="card border-info h-100 overflow-auto ml-2">

                <b-list-group flush >
                <b-list-group-item  v-for="item in listSelectedProc" :key="item.id" button class="p-2" variant="info" @click="FilterProc_click(item)" >
                {{ item.nom_processus }} 
                </b-list-group-item>

                </b-list-group>
            </div>


    </b-modal>




<loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";

export default {
     props: ['idProd','nomProd','dateProd'],
    computed: {
              rows_pagin() {
        return this.filterList.length
      },



    },
    mounted(){
        this.fetchList()
        this.fetchSelectedProcList()
    },
	data () {
		return {
            proc_filter:'Tous',
            perPage: 10,
            currentPage: 1,
            listSelectedProc: [],

            itemslist: [],
            filterList:[],

            fields: [
                {key: 'nom_entre', label: 'Entrée', sortable: true},
                {key: 'qte_init', label: 'Qté Stock', sortable: true},
                {key: 'qte', label: 'Quantité', sortable: true},
                {key: 'unite', label: 'Unité', sortable: true},
                { key: 'actions', label: '' }
            ],


            itemForm: {
                    id:'',
                    prod_production_id:'',
                    prod_produc_detail_id:'',
                    prod_entre_id:'',
                    nom_entre:'',
                    qte:0,
                    qte_init:0,
                    qte_final:0,
                    unite:''},


            //public trucs
            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            itemForm: {
                id: {
                    required
                },
                qte: {
                    required,
                    decimal
                }

            }
    },
    methods :{
        fetchListSortie()
        {

                this.$router.push({ 
                name: 'productionSortiesProd', 
                params: { 
                idProd: this.idProd,
                nomProd: this.nomProd,
                dateProd: this.dateProd,
                isEntr:true
                } 
                });

        },
        FilterProc_click(itm)
        {


                this.filterList=this.itemslist.filter(function (el)  {
                        return el.prod_produc_detail_id==itm.id
                })

            this.proc_filter = itm.nom_processus


             this.$bvModal.hide('filterModal')
        },
        filter_click(itm)
        {

            if(itm==1)
            {
                this.$root.$emit('bv::show::modal', 'filterModal')
            }
            else
            {
                this.filterList= this.itemslist
                this.proc_filter = 'Tous'
            }

        },

        fetchSelectedProcList: function () {
                    this.isLoading=true;
                    this.$http.get('/detailProcessParProd/'+this.idProd)
                    .then((result) => {
                                this.listSelectedProc = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },


        fetchList: function () {
                    this.isLoading=true;
                    this.$http.get('/entreesDeProduction/'+this.idProd)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;

                                this.filterList= result.data.data
                                this.proc_filter = 'Tous'
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },

        fetchListArts(itm)
        {

                    this.itemForm.id=itm.id
                    this.itemForm.prod_production_id=itm.prod_production_id
                    this.itemForm.prod_produc_detail_id=itm.prod_produc_detail_id
                    this.itemForm.prod_entre_id=itm.prod_entre_id
                    this.itemForm.nom_entre=itm.nom_entre
                    this.itemForm.qte=itm.qte_init
                    this.itemForm.qte_init=itm.qte_init
                    this.itemForm.qte_final=itm.qte_final
                    this.itemForm.unite=itm.unite


                    this.$refs.qte_field.focus();

        },

        handleSubmit() {
                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Sélectionnez un element et Reésayez !!',
                })
                }
                else 
                {

                    this.banqueEdit()

                }

            },

        banqueEdit(){

            this.isLoading=true;

            this.$http.patch('/prodProductEntres/'+this.itemForm.id,{
            prod_production_id:this.itemForm.prod_production_id,
            prod_produc_detail_id:this.itemForm.prod_produc_detail_id,
            prod_entre_id:this.itemForm.prod_entre_id,

            nom_entre:this.itemForm.nom_entre,
            qte:this.itemForm.qte,
            qte_init:this.itemForm.qte_init,
            qte_final:this.itemForm.qte_final,
            unite:this.itemForm.unite,


                })
                .then(response => {

                    this.isLoading=false;

                    let index = this.itemslist.findIndex(item => item.id === this.itemForm.id)
                    this.itemslist.splice(index, 1,response.data.data)


                    let indexf = this.filterList.findIndex(item => item.id === this.itemForm.id)
                    this.filterList.splice(indexf, 1,response.data.data)
                    
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données modifiées',
                    message: 'Les données sont modifiées correctement'
                    })


                    this.isSubmitted = false;
                    
                    this.itemForm.id=''
                    this.itemForm.nom_entre = ''

                    })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;


                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Modification',
                    message: 'Impossible  de modifier les données .. Réessayez !!'
                })
                });

        },

        NewBon()
        {
                    this.$router.push({ 
                        name: 'menuProduction'
                    });
        },
        terminer_click()
        {
            

            this.$confirm("Voulez-vous vraiment VALIDER les Entrées ??")
            .then(() => {

                                    this.isLoading=true;

                                    this.$http.get('/entreesValidProd/'+this.idProd+'/1')
                                        .then(response => {
                                            //metre a jour id
                                            console.log(response.data.data)

                                            this.isLoading=false;
                                            //afficher message
                                            this.flashMessage.show({
                                            status: 'success',
                                            title: 'Données sauvegardées',
                                            message: 'Les données sont sauvegardées correctement '

                                        })
                                            //Get list of process

                                                                    this.$router.push({ 
                                                                    name: 'productionSortiesProd', 
                                                                    params: { 
                                                                    idProd: this.idProd,
                                                                    nomProd: this.nomProd,
                                                                    dateProd: this.dateProd
                                                                    } 
                                                    });
                                        })
                                        .catch(error=> {console.log(error)
                                        this.isLoading=false;


                                    this.flashMessage.show({
                                        status: 'error',
                                        title: 'Erreur De Sauvegarde',
                                        message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                                        })
                                        });



                                    })
                                    .catch(error=> {
                                    console.log(error)

                                    });




        },
    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>