import { render, staticRenderFns } from "./RappProdProcResum.vue?vue&type=template&id=33003b10&scoped=true&"
import script from "./RappProdProcResum.vue?vue&type=script&lang=js&"
export * from "./RappProdProcResum.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33003b10",
  null
  
)

export default component.exports