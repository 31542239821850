<template>
<div>


        <div class="row">
            <div class="col">



      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    

                    <div class="col">
                        
                        <h4 class="ml-3 mt-2 font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Productions (Sorties) </h4> 

                        <div class="mt-1 ml-2  text-secondary">    - Production : <span class="text-info"> {{nomProd}} </span>  </div>
                        <div class="mt-1 ml-2 text-secondary">    - Date : <span class="text-info"> {{dateProd}} </span>  </div>
                        <div class="mt-1 ml-2 text-secondary">    
                            - Processus : <span class="text-primary"> {{proc_filter}} </span>  
                            <b-button @click="filter_click(1)"    class="ml-1 mr-1 p-1 text-decoration-none" variant="link">
                            <i class="fa fa-filter"></i>                
                            </b-button> 

                            <b-button @click="filter_click(0)"    class="mr-1 p-1 text-secondary" variant="link">
                            <i class="fa fa-eraser"></i>                
                            </b-button> 
                        </div>


                        <div v-show="proc_filter!='Tous'" class="mt-1 mb-2 ml-2 text-secondary">    - Total Prod : <span class="text-info"> {{total_mm}} </span>  </div>

                    </div>

                    <div class="col">

                                    <b-button pill size="sm"  class="m-1 float-right text-secondary" variant="link" @click="NewBon">
                                                <i class="fa fa-home fa-2x"></i>
                                    </b-button>

                                    <b-button pill size="sm"  class="m-2 float-right" variant="outline-info" @click="fetchList">
                                        <i class="fa fa-sync-alt"></i>
                                        <span> Actu.</span> 
                                    </b-button>

                                    <b-button v-if="isEntr"  pill size="sm"  class="m-2 float-right" variant="outline-secondary" @click="fetchListEntrees">
                                        <i class="fa fa-file-alt"></i>
                                        <span> Entrées</span> 
                                    </b-button>

                                    <b-button v-if="!isEntr" pill size="sm"  class="m-2 float-right" variant="success" @click="terminer_click">
                                        <i class="fa fa-check-square"></i>
                                        <span> Valider</span> 
                                    </b-button>
        


                    </div>
            </div>
       


        <b-table 
        id="main-table"
        :per-page="perPage"
        :current-page="currentPage"

        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="filterList"
        >
            <template #cell(actions)="row">

                    <b-button pill size="sm" class="mr-2 float-right" variant="outline-secondary" @click="fetchListArts(row.item)">
                            <i class="fa fa-highlighter"></i>
                    
                    </b-button>
                </template>
        
        </b-table>
                <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>



      </div>
                

            </div>

            <div class="ml-2 col-3">
                              <div class="rounded-lg shadow border border-white bg-white">


                    <div class="mt-4 d-flex justify-content-center text-secondary">
                    <i class="fas fa-cubes fa-3x"></i>
                    </div>

                    <div class=" d-flex justify-content-center text-secondary mb-2">
                    <h5 class="mt-2 font-weight-lighter">{{itemForm.nom_sortie}}</h5> 
                    </div>





       
            <form @submit.prevent="handleSubmit" >
          
                               <div v-if="d_get_gst_prod_stock" class="form-group m-3">
                                <label for="depot_id" class="form-control-sm mb-0"> Dépôt :</label>

                                            <select class="form-control form-control-sm" id="depot_id" v-model="idDep">

                                            <option v-for="item in depots" :key="item.id" v-bind:value="item.id">   
                                                {{ item.nom }}
                                            </option>


                                            </select>

                              </div>


            <div class="form-group m-3">
                <label for="qte_prod" class="form-control-sm mb-0">Quantité De Production ({{itemForm.unite_prod}})</label>

                <b-button v-show="itemForm.is_fini" @click="calcul_prodU_click"  size="sm"  class="float-left p-1 text-success" variant="link">
                <i class="fa fa-calculator"></i>                
                </b-button> 


                <input ref="qte_field" type="text" v-model="itemForm.qte_prod" id="qte_prod" name="qte_prod" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.qte_prod.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.qte_prod.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.qte_prod.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>

            <div v-show="itemForm.is_fini" class="form-group m-3">
                <label for="qte_stock" class="form-control-sm mb-0">Quantité De Stockage ({{itemForm.unite_stock}})</label>

                <b-button @click="calcul_stockU_click"  size="sm"  class="float-left p-1 text-decoration-none" variant="link">
                <i class="fa fa-calculator"></i>                
                </b-button> 

                <input type="text" v-model="itemForm.qte_stock" id="qte_stock" name="qte_stock" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.qte_stock.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.qte_stock.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.qte_stock.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>


            <div class="form-group m-3 " v-if="itemForm.id && lotshow && d_get_gst_prod_stock">

                <label for="lot_id" class="form-control-sm mb-0">Lot :</label>

                <b-button @click="addLot_click"  size="sm"  class="ml-1 mr-1 float-left p-1 text-decoration-none" variant="link">
                <i class="fa fa-plus-square"></i>                
                </b-button> 

                <select class="form-control form-control-sm" id="lot_id" v-model="itemForm.lot_id"
                :class="{ 'is-invalid': isSubmitted && $v.itemForm.lot_id.$error }">
                <option v-for="item in ListLots" :key="item.id" v-bind:value="item.id">   
                {{ item.nom }} - {{ item.qte }}
                </option>
                </select>
                <div v-if="isSubmitted && !$v.itemForm.lot_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>


            </div>


            <div v-show="itemForm.id" class="form-group m-3">
                <b-button type="submit" class="btn btn-secondary btn-block" id="mm">
                Valider
                </b-button>
            </div>





            </form>



                                 </div>
            </div>
        </div>



 <b-modal  id="lolmodal" title="Nouveau Lot"  size="lg" hide-footer>


           <form @submit.prevent="handleSubmitLot()">


            <div class="custom-control custom-switch">
            <input type="checkbox"
             class="custom-control-input" 
             id="etat"
                    v-model="lotParDefaut"
                    name="etat"
                    value=true
                    unchecked-value=false

             >

            <label class="custom-control-label mb-2" for="etat"> Lot Par Défaut</label>
            </div>


            <div class="row" v-if="!lotParDefaut">
            <div class="form-group col">
                <label for="date_exp" class="mr-sm-2">Date Exp</label>
                    <b-form-datepicker
                    class="mr-sm-2"
                    id="datepicker-date_exp"
                    placeholder="date"
                    v-model="itemFormLot.date_exp"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
            </div>

            <div class="form-group col">
            <b-button    class="ml-2 float-left p-0" variant="link" @click="changeLot">
                <i class="fa fa-retweet"></i>
                <span> </span> 
            </b-button>
                <label for="nom">Nom</label>
                <input type="text" v-model="itemFormLot.nom" id="nom" name="nom" class="form-control" />
               
            </div>
            </div>



            <div class="form-group mt-4">
                <button type="submit" class="btn  btn-primary btn-block">Ajouter</button>
            </div>

        </form>

</b-modal>

    <!-- le modal -->
    <b-modal id="filterModal"  hide-footer hide-header>



            <div class="card border-info h-100 overflow-auto ml-2">

                <b-list-group flush >
                <b-list-group-item  v-for="item in listSelectedProc" :key="item.id" button class="p-2" variant="info" @click="FilterProc_click(item)" >
                {{ item.nom_processus }} 
                </b-list-group-item>

                </b-list-group>
            </div>


    </b-modal>


<loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";

export default {
         props: ['idProd','nomProd','dateProd','isEntr'],
    computed: {
              rows_pagin() {
        return this.filterList.length
      },
          d_get_gst_prod_stock() {
    return this.$store.getters.get_gst_prod_stock;
    },
        user_dep() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.dep;
      }
      else
      {
            return 0;
      }
    },
      total_mm: function() {
      return (this.filterList.reduce((a, b) => +a + +b.qte_prod, 0)).toFixed(2);
    },
    },
    mounted(){
        this.fetchList()
        this.fetchSelectedProcList()

        if(this.d_get_gst_prod_stock)
        {
             this.fetchDepots()
        }
    },
	data () {
		return {

            proc_filter:'Tous',
            perPage: 10,
            currentPage: 1,
            listSelectedProc: [],

            itemslist: [],
            filterList:[],


            fields: [
                {key: 'nom_sortie', label: 'Sortie'},
                {key: 'qte_prod', label: 'Qté.Prod'},
                {key: 'unite_prod', label: 'U.Prod'},
                {key: 'qte_stock', label: 'Qté.Stock'},
                {key: 'unite_stock', label: 'U.Stock'},
                {key: 'nom_lot', label: 'Lot'},

                { key: 'actions', label: '' }
            ],


            itemForm: {
                    id:'',
                    prod_production_id:'',
                    prod_produc_detail_id:'',
                    prod_sorti_id:'',

                    nom_sortie:'',
                    qte_prod:0,
                    qte_stock:0,
                    unite_prod:'',
                    unite_stock:'',

                    is_fini:false,
                    article_entr_id:'',
                    prod_to_stock:'',

                    nom_lot:'',
                    lot_id:0,
                    nom_depot:''
                    },
            itemFormLot: {
                    nom:'',
                    qte:'',
                    date:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    date_exp:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    depot_id:'',
                    article_id:''},
            ListLots:[],
            depots:[],
            idDep:'',

            lotshow:false,
            lotParDefaut:false,
            //public trucs
            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            itemForm: {
                id: {
                    required
                },
                qte_stock: {
                    required,
                    decimal
                },
                qte_prod: {
                    required,
                    decimal
                },
                lot_id: {
                    required
                }
                

            }
    },
    methods :{
        fetchListEntrees()
        {
                                           this.$router.push({ 
                                            name: 'productionEntreesProd', 
                                            params: { 
                                                    idProd: this.idProd,
                                                    nomProd: this.nomProd,
                                                    dateProd: this.dateProd
                                            } });
        },
        FilterProc_click(itm)
        {

                this.filterList=this.itemslist.filter(function (el)  {
                        return el.prod_produc_detail_id==itm.id
                })

            this.proc_filter = itm.nom_processus


             this.$bvModal.hide('filterModal')
        },
        filter_click(itm)
        {

            if(itm==1)
            {
                this.$root.$emit('bv::show::modal', 'filterModal')
            }
            else
            {
                this.filterList= this.itemslist
                this.proc_filter = 'Tous'
            }

        },

        fetchSelectedProcList: function () {
                    this.isLoading=true;
                    this.$http.get('/detailProcessParProd/'+this.idProd)
                    .then((result) => {
                                this.listSelectedProc = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },



        fetchDepots: function () {


                    //chager les depots:
                    this.isLoading=true;
                    this.$http.get('/depotPerson/'+this.user_dep)
                    .then((result) => {
                                this.depots = result.data.data
                                this.isLoading=false;

                                
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },

        fetchList: function () {
                    this.isLoading=true;
                    this.$http.get('/sortieDeProduction/'+this.idProd)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;

                                
                                this.filterList= result.data.data
                                this.proc_filter = 'Tous'
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },

        fetchListArts(itm)
        {

                        if(itm.is_fini==1)
                        {
                                    if(this.d_get_gst_prod_stock==false)
                                    {
                                                                                this.itemForm.nom_lot="--"
                                                                                this.itemForm.lot_id=0

                                                                                this.lotshow = false

                                                                                this.itemForm.id=itm.id
                                    }
                                    else
                                    {
                                                                if(this.idDep)
                                                                {
                                                                    this.itemForm.nom_lot=''
                                                                    this.itemForm.lot_id=''
                                                                    this.lotshow = true
                                                                    this.fetchLots(itm.article_entr_id)

                                                                    this.itemForm.id=itm.id
                                                                }
                                                                else
                                                                {
                                                                    this.flashMessage.show({
                                                                    status: 'info',
                                                                    title: 'Sélectionnez un Dépôt et Reésayez !!',
                                                                    })
                                                                }
                                    }



                        }
                        else
                        {
                                            this.itemForm.nom_lot="--"
                                            this.itemForm.lot_id=0

                                            this.lotshow = false

                                            this.itemForm.id=itm.id
                        }


                    this.itemForm.prod_production_id=itm.prod_production_id
                    this.itemForm.prod_produc_detail_id=itm.prod_produc_detail_id
                    this.itemForm.prod_sorti_id=itm.prod_sorti_id
                    this.itemForm.nom_sortie=itm.nom_sortie
                    this.itemForm.qte_prod=''
                    this.itemForm.qte_stock=''
                    this.itemForm.unite_prod=itm.unite_prod
                    this.itemForm.unite_stock=itm.unite_stock


                    this.itemForm.is_fini=itm.is_fini
                    this.itemForm.article_entr_id=itm.article_entr_id
                    this.itemForm.prod_to_stock=itm.prod_to_stock


                    this.$refs.qte_field.focus();

        },

        handleSubmit() {


                //set nom lot
                if(this.itemForm.lot_id && this.lotshow && this.idDep)
                {
                let dp = this.depots.findIndex(item => item.id === this.idDep)
                let am = this.ListLots.findIndex(item => item.id === this.itemForm.lot_id)
                this.itemForm.nom_lot= this.ListLots[am].nom +" ("+ this.depots[dp].nom+")"
                }


                //set nom lot
                if(this.itemForm.is_fini==0)
                {
                    this.itemForm.qte_stock = this.itemForm.qte_prod

                }


                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Sélectionnez un element et Reésayez !!',
                })
                }
                else 
                {

                    this.banqueEdit()

                }

            },

        banqueEdit(){


            this.isLoading=true;

            this.$http.patch('/prodProductSorties/'+this.itemForm.id,{
            prod_production_id:this.itemForm.prod_production_id,
            prod_produc_detail_id:this.itemForm.prod_produc_detail_id,
            prod_sorti_id:this.itemForm.prod_sorti_id,

            nom_sortie:this.itemForm.nom_sortie,
            qte_prod:this.itemForm.qte_prod,
            qte_stock:this.itemForm.qte_stock,

            unite_prod:this.itemForm.unite_prod,
            unite_stock:this.itemForm.unite_stock,
            is_fini:this.itemForm.is_fini,

            article_entr_id:this.itemForm.article_entr_id,
            prod_to_stock:this.itemForm.prod_to_stock,

            nom_lot:this.itemForm.nom_lot,
            lot_id:this.itemForm.lot_id


                })
                .then(response => {

                    this.isLoading=false;

                    let index = this.itemslist.findIndex(item => item.id === this.itemForm.id)
                    this.itemslist.splice(index, 1,response.data.data)
             
                    let indexf = this.filterList.findIndex(item => item.id === this.itemForm.id)
                    this.filterList.splice(indexf, 1,response.data.data)
                    
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données modifiées',
                    message: 'Les données sont modifiées correctement'
                    })


                    this.isSubmitted = false;

                    this.itemForm.id=''
                    this.itemForm.nom_entre = ''

                    })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;


                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Modification',
                    message: 'Impossible  de modifier les données .. Réessayez !!'
                })
                });

        },
        fetchLots(id_art) {
                    this.isLoading=true;
                    this.$http.get('/lotsArtDep/'+id_art+'/'+this.idDep)
                    .then((result) => {
                                this.ListLots = result.data.data
                                this.isLoading=false;


                                if(this.ListLots.length>0)
                                {
                                        this.itemForm.lot_id=this.ListLots[0].id
                                }
                                else
                                {
                                        this.itemForm.lot_id=''
                                }
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },

        addLot_click()
        {
            this.$root.$emit('bv::show::modal', 'lolmodal')
        },
        handleSubmitLot() {

                if(this.lotParDefaut)
                {
                    this.itemFormLot.date_exp='2200-1-1',
                    this.itemFormLot.nom='Par Defaut'
                }




                if (this.itemFormLot.date_exp && this.itemFormLot.nom) {


                this.itemFormLot.nom,
                this.itemFormLot.qte=0,
                this.itemFormLot.date =new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                this.itemFormLot.date_exp,
                this.itemFormLot.depot_id=this.idDep,
                this.itemFormLot.article_id = this.itemForm.article_entr_id,


                    this.isLoading=true;

                    this.$http.post('/lots',{
                   
                    nom:this.itemFormLot.nom,
                    qte:this.itemFormLot.qte,
                    date:this.itemFormLot.date,
                    date_exp:this.itemFormLot.date_exp,
                    depot_id:this.itemFormLot.depot_id,
                    article_id:this.itemFormLot.article_id,



                })
                .then(response => {

                this.isLoading=false;

               this.ListLots.unshift(response.data.data);

               this.itemForm.lot_id = response.data.data.id
                this.$bvModal.hide('lolmodal')

                    // le lot par defaut
                    this.lotParDefaut=false

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


               this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });








                }
                else 
                {


                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }

        },

            changeLot()
            {

            this.itemFormLot.nom = "Lot_"+this.itemFormLot.date_exp 
            },

            calcul_stockU_click()
            {

              

             if(isNaN(this.itemForm.qte_prod) || isNaN(this.itemForm.prod_to_stock))
             {
                this.flashMessage.show({
                status: 'info',
                title: 'Entrez une quantité de production !!',
                })
                
             }
            else
            {
                    this.itemForm.qte_stock= (Number(this.itemForm.qte_prod) * Number(this.itemForm.prod_to_stock)).toFixed(2) 
            }


            },


            calcul_prodU_click()
            {

              

             if(isNaN(this.itemForm.qte_stock) || isNaN(this.itemForm.prod_to_stock))
             {
                this.flashMessage.show({
                status: 'info',
                title: 'Entrez une quantité du Stock !!',
                })
                
             }
            else
            {
                    this.itemForm.qte_prod= (Number(this.itemForm.qte_stock) / Number(this.itemForm.prod_to_stock)).toFixed(2) 
            }


            },

            NewBon()
            {
                    this.$router.push({ 
                        name: 'menuProduction'
                    });
            },
            terminer_click()
            {
                
            this.$confirm("Voulez-vous vraiment VALIDER les Sorties ??")
            .then(() => {
                this.isLoading=true;

                this.$http.get('/sortieValidProd/'+this.idProd+'/1')
                    .then(response => {
                        //metre a jour id
                        console.log(response.data.data)

                        this.isLoading=false;
                        //afficher message
                        this.flashMessage.show({
                        status: 'success',
                        title: 'Données sauvegardées',
                        message: 'Les données sont sauvegardées correctement '

                    })
                        //Get list of process
                        this.$router.push({ 
                            name: 'menuProduction'
                        });


                    })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;


                this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Sauvegarde',
                    message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                    })
                    });


                                                    })
                                    .catch(error=> {
                                    console.log(error)

                                    });


            },
    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>