<template>
<div>
  
                        
      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    
<div class="col-4">
            <h4 class="m-3 font-weight-lighter text-info float-left"> <span class="fa fa-box-open"></span> Production (Les Entrées)    </h4> 


</div>





<div class="col">
    
                <b-button pill size="sm"  class="m-3 float-right" variant="info" @click="fetchList">
                    <i class="fa fa-sync-alt"></i>
                    <span> Actualiser</span> 
                </b-button>

                <b-button pill size="sm"  class="m-3 float-right" variant="secondary" @click="exportDox">
                            <i class="fa fa-print"></i>
                    <span> Impr</span> 
                </b-button>

                <b-button pill size="sm"  class="m-3 float-right" variant="success" @click="add_click($event.target)">
                            <i class="fa fa-plus-square"></i>
                    <span> Ajouter</span> 
                </b-button>

</div>
            </div>
       



        <b-table 
        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        >
            <template #cell(actions)="row">
                    <b-button pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="del_click(row.item)">
                                <i class="fa fa-trash-alt"></i>
                        
                    </b-button>
                    <b-button pill size="sm" class="mr-2 float-right" variant="outline-primary" @click="edit_click(row.item, row.index, $event.target)">
                            <i class="fa fa-pencil-alt"></i>
                    
                    </b-button>
                </template>
        
        </b-table>
      </div>


    <!-- le modal -->
    <b-modal id="banqModal" :title="itemForm.nom"  hide-footer>
           <form @submit.prevent="handleSubmit">
            <div class="form-group">
                <label for="name">Nom :</label>
                <input type="text" v-model="itemForm.nom" id="name" name="name" class="form-control"
                :class="{ 'is-invalid': isSubmitted && $v.itemForm.nom.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.nom.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>

            <div class="form-group">
                <label for="unite">Unité :</label>
                <input type="text" v-model="itemForm.unite" id="unite" name="unite" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.unite.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.unite.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>


            <div class="form-group">
                <label for="qte">Quantité :</label>
                <input type="text" v-model="itemForm.qte" id="qte" name="qte" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.qte.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.qte.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.qte.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>


            <div class="custom-control custom-switch">
            <input type="checkbox"
             class="custom-control-input" 
             id="etat"
                    v-model="itemForm.etat"
                    name="etat"
                    value=true
                    unchecked-value=false

             >

            <label class="custom-control-label" for="etat"> Actif</label>
            </div>

            <div class="form-group mt-4">
                <button type="submit" class="btn  btn-primary btn-block">{{ModalType}}</button>
            </div>


        </form>
    </b-modal>


    <!-- les trucs public -->

    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";
import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
import { saveAs } from 'file-saver';

export default {

    mounted(){
       this.fetchList()
    },
	data () {
		return {
            centres:[],
            itemslist: [],
            itemForm: {
                    id:'',
                    nom:'',
                    unite:'',
                    qte:'',
                    etat:''},
            fields: [
				{key: 'nom', label: 'Nom', sortable: true},
                {key: 'qte', label: 'Quantité'},
                {key: 'unite', label: 'Unité'},
                {key: 'etat', label: 'Actif', 
                formatter: (value) => {
                 return value ? 'Oui' : 'Non'
                }},
                { key: 'actions', label: '' }
            ],
            //public trucs
            ModalEdit: false,
            ModalType:'Ajouter',
            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            itemForm: {
                nom: {
                    required
                },
                unite: {
                    required
                },
                qte: {
                    required,
                    decimal
                }
            }
    },
    methods :{



exportDox()
        {


        let datrw =[new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 5000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Nom",
                                            spacing: {
                                                before: 100,
                                                after: 100
                                            },
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Quantité",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                                                width: {
                                                    size: 3000,
                                                    type: WidthType.DXA,
                                                },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Unité",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        



                    ],
                })]

        this.itemslist.forEach(function(item){

        let r = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 5000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.nom,
                                        spacing: {
                                        before: 50,
                                        after: 50
                                    },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.qte.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: item.unite,
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                    ],
                })

        datrw.push(
        r
        )
        });

                const doc = new Document();

        const table = new Table({

            rows:  datrw
        })



                doc.addSection({


                    properties: {
                        page: {
                            pageNumbers: {
                                start: 1,
                                formatType: PageNumberFormat.DECIMAL,
                            },
                        },
                    },
                    headers: {
                        default: new Header({
                            children: [
                                new Paragraph({
                                    children: [
                            new TextRun({
                                text: "تعاونية حليب الريش",
                                color: '636572',
                                bold: true,

                                })
                                    ],
                                border: {
                                bottom: {
                                        color: "636572",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.TITLE,
                                    alignment: AlignmentType.CENTER,
                                }),
                            ],
                        }),
                    },
                    footers: {
                        default: new Footer({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            children: ["Page : ", PageNumber.CURRENT],
                                        }),
                                        new TextRun({
                                            children: [" Sur ", PageNumber.TOTAL_PAGES],
                                        }),
                                    ],
                                }),
                            ],
                        }),
                    },



                    children: [

                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "Liste Des Entrées (Matière Première)",
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }), 

                            ],

                            heading: HeadingLevel.HEADING_2,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),




                        table
                        
                        ]
                })







            Packer.toBlob(doc).then(blob => {

        saveAs(blob, "Liste_Entrées_Prod.docx");

            });

        },







        itemslisttore(){
            this.isLoading=true;

            this.$http.post('/prodEntres',{
            nom:this.itemForm.nom,
            unite:this.itemForm.unite,
            qte:this.itemForm.qte,
            etat:this.itemForm.etat

                })
                .then(response => {

                    this.isLoading=false;
                    this.itemslist.unshift(response.data.data);
                    this.$bvModal.hide('banqModal')

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


               this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },
        banqueEdit(){

            this.isLoading=true;

            this.$http.patch('/prodEntres/'+this.itemForm.id,{
            nom:this.itemForm.nom,
            unite:this.itemForm.unite,
            qte:this.itemForm.qte,
            etat:this.itemForm.etat

                })
                .then(response => {

                    this.isLoading=false;
                    console.log(response.data)
                    this.$bvModal.hide('banqModal')

                    let index = this.itemslist.findIndex(item => item.id === this.itemForm.id)
                    this.itemslist.splice(index, 1,response.data.data)
                    
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données modifiées',
                    message: 'Les données sont modifiées correctement'
                    })


                    })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;


                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Modification',
                    message: 'Impossible  de modifier les données .. Réessayez !!'
                })
                });

        },
        banqueDell(){

            this.isLoading=true;

            this.$http.delete('/prodEntres/'+this.itemForm.id)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                    let index = this.itemslist.findIndex(item => item.id === this.itemForm.id)
                    this.itemslist.splice(index, 1)




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
        add_click(button) {
        this.itemForm.id=''
        this.itemForm.nom=''
        this.itemForm.unite='',
        this.itemForm.qte='',
        this.itemForm.etat=true,

        this.ModalEdit=false;
        this.ModalType='Ajouter'

        this.$root.$emit('bv::show::modal', 'banqModal', button)
      },
        edit_click(item, index, button) {

                this.$confirm("Voulez-vous vraiment modifier cet élément ??")
                 .then(() => {
                this.itemForm.id=item.id
                this.itemForm.nom=item.nom
                this.itemForm.unite=item.unite,
                this.itemForm.qte=item.qte,
                this.itemForm.etat=item.etat

                this.ModalEdit=true;
                this.ModalType='Modifier'

                this.$root.$emit('bv::show::modal', 'banqModal', button)
                })
                .catch(error=> {
                console.log(error)

                });


      },
        del_click(item) {

                this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                 .then(() => {
                this.itemForm.id=item.id

                                    this.banqueDell()
                                })
                                .catch(error=> {
                                console.log(error)

                                    });




      },
        fetchList: function () {
                    this.isLoading=true;
                    this.$http.get('/prodEntres')
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });






        },

        handleSubmit() {


                if(!this.itemForm.depot_centre)
                {
                this.itemForm.centre_id=0
                }

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                            if(this.ModalEdit)
                            {
                                this.banqueEdit()
                            }
                            else
                            {

                                this.itemslisttore()
                            }   

                }

            }

    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>