<template>
<div>
  
            <div class="rounded-lg shadow border border-white bg-white mb-4">
                        <div class="row justify-content-centre align-items-center">    

              <div class="col">
  





        <form @submit.prevent="handleSubmitBonCMD()">
                <div class="input-group  border border-white rounded bg-white p-2  justify-content-centre align-items-center text-info">


                                            <label for="proc_id" class="mr-sm-2 ml-3 pt-2"> Processus :</label>

                                            <select class="form-control border rounded mr-4" id="proc_id" v-model="itemFormBon.proc_id"
                                            :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.proc_id.$error }">

                                            <option v-for="item in procList" :key="item.id" v-bind:value="item.id">   
                                                {{ item.nom }}
                                            </option>

                                            </select>



        
                <label for="dateDebut" class="mr-sm-2  pt-2 pl-1"> De :</label>
                    <b-form-datepicker
                    class="mr-sm-2 border rounded"
                    id="datepicker-dateDebut"
                    placeholder="date fin"
                    v-model="itemFormBon.dateDebut"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
        

                       
                <label for="dateFin" class="mr-sm-2  pt-2 pl-1"> Au :</label>
                    <b-form-datepicker
                    class="mr-sm-2 border rounded"
                    id="datepicker-dateFin"
                    placeholder="date fin"
                    v-model="itemFormBon.dateFin"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
      



                                    <div class="input-group-prepend">
                                        <button class="btn  btn-info rounded-pill m-2" type="submit" id="button-addon1">   
                                        <span class="fa fa-search"></span> Liste
                                        </button>
                                    </div>

                                

                </div>
        </form>





            </div>
                        </div>          
            </div>  



 
      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    

            <h4 class="col-3 ml-3 font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Processus</h4> 

            <h5 class="col ml-3 font-weight-lighter text-primary">  Total Entrées : {{total_entr}}</h5> 
            <h5 class="col ml-3 font-weight-lighter text-primary">  Total Production : {{total_prod}}</h5> 
                    <div class="col-2">


                                    <b-button @click="exportDox" pill size="sm"  class="m-3 float-right" variant="info">
                                        <i class="fa fa-print"></i>
                                        <span> Rapport</span> 
                                    </b-button>

                    </div>
            </div>
       


                <b-table 
                id="main-table"
                :per-page="perPage"
                :current-page="currentPage"

                sort-icon-left
                head-variant="light"

                :items="itemslistprod" 
                :fields="fieldstt">
                        <template #cell(show_details)="row">
                            <b-button pill size="sm" class="mr-2 float-right" variant="outline-primary" @click="row.toggleDetails">
                                <i class="fa fa-bars"></i>
                            {{ row.detailsShowing ? 'Masquer' : 'Afficher'}}  les détails
                            </b-button>
                        </template>

                <template #row-details="row">
                    <div>
                        <div class="row">
                                <div class="col-5 card m-2 p-0 bg-light">
                                    <b-table :items="row.item.data_entres" :fields="fieldsEntr" small>
                                      <template #head(nom_entre)="data">
                                                <span class="text-info font-weight-light">{{ data.label }}</span>
                                       </template>    
                                       <template #head(qte_init)="data">
                                                <span class="text-info font-weight-light">{{ data.label }}</span>
                                       </template>   
                                       <template #head(qte)="data">
                                                <span class="text-info font-weight-light">{{ data.label }}</span>
                                       </template> 
                                       <template #head(unite)="data">
                                                <span class="text-info font-weight-light">{{ data.label }}</span>
                                       </template> 

                                       </b-table> 

                                    <div class="h-100">
                                    </div>
                                    <div class="d-flex justify-content-center text-secondary">
                                    <h5 class="font-weight-lighter float-bottom">Total  : {{row.item.data_entres.reduce((a, b) => +a + +b.qte, 0)}}</h5>
                                    </div>
                                </div>
                                <div class="col card m-2 p-0 bg-light">
                                    <b-table :items="row.item.data_sortie" :fields="fieldsSorties" small> 

                                            <template #head(nom_sortie)="data">
                                                <span class="text-primary font-weight-light">{{ data.label }}</span>
                                            </template>    
                                            <template #head(qte_prod)="data">
                                                <span class="text-primary font-weight-light">{{ data.label }}</span>
                                            </template>  
                                            <template #head(unite_prod)="data">
                                                <span class="text-primary font-weight-light">{{ data.label }}</span>
                                            </template>  
                                            <template #head(qte_stock)="data">
                                                <span class="text-primary font-weight-light">{{ data.label }}</span>
                                            </template>     
                                            <template #head(unite_stock)="data">
                                                <span class="text-primary font-weight-light">{{ data.label }}</span>
                                            </template>  
                                            <template #head(nom_lot)="data">
                                                <span class="text-primary font-weight-light">{{ data.label }}</span>
                                            </template> 
                                    </b-table> 


                                    <div class="h-100">
                                    </div>

                                    <div class=" d-flex justify-content-center text-secondary">
                                    <h5 class="font-weight-lighter">Total Prod : {{row.item.data_sortie.reduce((a, b) => +a + +b.qte_prod, 0)}}</h5>
                                    </div>

                                </div>
                        </div>

                    </div>
                </template>
                </b-table>

                <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>

      </div>





    <!-- les trucs public -->
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";
import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun, BorderStyle } from "docx";
import { saveAs } from 'file-saver';

export default {
      computed: {
    user_dep() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.dep;
      }
      else
      {
            return 0;
      }
    },
      rows_pagin() {
        return this.itemslistprod.length
      },

      total_entr: function() {
      return this.itemslistprod.reduce((a, b) => +a + +(b.data_entres.reduce((s, q) => +s + +q.qte, 0)), 0);
    },
      total_prod: function() {
      return this.itemslistprod.reduce((a, b) => +a + +(b.data_sortie.reduce((s, q) => +s + +q.qte_prod, 0)), 0);
    },
    },
    mounted(){
      this.fetchCentre()
    },
	data () {
		return {
            perPage: 10,
            currentPage: 1,
            itemFormBon: {
                    dateDebut:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    dateFin:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    proc_id:''
            },
            procList:[],
            itemslistprod: [],

            fieldsEntr: [
				{key: 'nom_entre', label: 'Entrée'},
                {key: 'qte_init', label: 'Stock'},
                {key: 'qte', label: 'Quantité'},
				{key: 'unite', label: 'U'},
            ],

            fieldsSorties: [
				{key: 'nom_sortie', label: 'Produit'},
                {key: 'qte_prod', label: 'Qté Prod'},
                {key: 'unite_prod', label: 'U.Prod'},
				{key: 'qte_stock', label: 'Qté Stock'},
				{key: 'unite_stock', label: 'U.stock'},
                {key: 'nom_lot', label: 'lot'},
            ],


        fieldstt: [
                {key: 'nom', label: 'Nom'},
                {key: 'date', label: 'Date'},
                {key: 'show_details', label: ''}
                ],

            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            itemFormBon: {

                dateDebut: {
                    required
                },
                dateFin: {
                    required
                },
                proc_id: {
                    required
                }
            }

    },
    methods :{

                exportDox()
                        {

        let mm=this.procList.findIndex(item => item.id === this.itemFormBon.proc_id)

        let process= this.procList[mm].nom




                        let datrwglobal=[]


                        let datrw=[]


                        this.itemslistprod.forEach(function(item){


//le entres 
                        let datrwEntre =[new TableRow({
                                    children: [
                                        new TableCell({
                                            width: {
                                                size: 50,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Entrées",
                                                            spacing: {
                                                            before: 100,
                                                            after: 100
                                                        },
                                                        heading: HeadingLevel.HEADING_5,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                        new TableCell({
                                            width: {
                                                size: 50,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Quantité",
                                                            spacing: {
                                                            before: 100,
                                                            after: 100
                                                        },
                                                        heading: HeadingLevel.HEADING_5,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                                    ],
                                })]




                        item.data_entres.forEach(function(ee){


                        let rentre = new TableRow({
                                    children: [
                                        new TableCell({
                                            width: {
                                                size: 50,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: ee.nom_entre,
                                                        spacing: {
                                                        before: 50,
                                                        after: 50
                                                    },
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                        new TableCell({
                                            width: {
                                                size: 50,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: ee.qte.toString(),
                                                        spacing: {
                                                        before: 50,
                                                        after: 50
                                                    },
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                                    ],
                                })

                        datrwEntre.push(
                        rentre
                        )

                        });
//pied:entrées:
                        let rent_peid = new TableRow({
                                    children: [


                                        new TableCell({
                                            width: {
                                                size: 100,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Total : "+item.data_entres.reduce((a, b) => +a + +b.qte, 0),
                                                        spacing: {
                                                        before: 50,
                                                        after: 50
                                                    },
                                                        heading: HeadingLevel.HEADING_5,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        columnSpan:2,
                                        }),

                                    ],
                                })


                        datrwEntre.push(
                        rent_peid
                        )

                        const tabEntre = new Table({

                            rows:  datrwEntre
                        })
//le Soties 
                        let datrwSorties =[new TableRow({
                                    children: [
                                        new TableCell({
                                            width: {
                                                size: 40,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Sortie",
                                                            spacing: {
                                                            before: 100,
                                                            after: 100
                                                        },
                                                        heading: HeadingLevel.HEADING_5,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                        new TableCell({
                                            width: {
                                                size: 20,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Qté Prod",
                                                            spacing: {
                                                            before: 100,
                                                            after: 100
                                                        },
                                                        heading: HeadingLevel.HEADING_5,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                        new TableCell({
                                            width: {
                                                size: 20,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Qté Stock",
                                                            spacing: {
                                                            before: 100,
                                                            after: 100
                                                        },
                                                        heading: HeadingLevel.HEADING_5,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                                                                new TableCell({
                                            width: {
                                                size: 20,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Lot",
                                                            spacing: {
                                                            before: 100,
                                                            after: 100
                                                        },
                                                        heading: HeadingLevel.HEADING_5,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                    ],
                                })]



                        item.data_sortie.forEach(function(ss){


                        let rsortie = new TableRow({
                                    children: [
                                        new TableCell({
                                            width: {
                                                size: 40,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: ss.nom_sortie,
                                                        spacing: {
                                                        before: 50,
                                                        after: 50
                                                    },
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                        new TableCell({
                                            width: {
                                                size: 20,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: ss.qte_prod.toString(),
                                                        spacing: {
                                                        before: 50,
                                                        after: 50
                                                    },
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                        new TableCell({
                                            width: {
                                                size: 20,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: ss.qte_stock.toString(),
                                                        spacing: {
                                                        before: 50,
                                                        after: 50
                                                    },
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                                                                new TableCell({
                                            width: {
                                                size: 20,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: ss.nom_lot,
                                                        spacing: {
                                                        before: 50,
                                                        after: 50
                                                    },
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                    ],
                                })

                        datrwSorties.push(
                        rsortie
                        )

                        });

//pied:entrées:
                        let sort_peid = new TableRow({
                                    children: [


                                        new TableCell({
                                            width: {
                                                size: 100,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Total Production : "+item.data_sortie.reduce((a, b) => +a + +b.qte_prod, 0),
                                                        spacing: {
                                                        before: 50,
                                                        after: 50
                                                    },
                                                        heading: HeadingLevel.HEADING_5,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        columnSpan:4,
                                        }),

                                    ],
                                })


                        datrwSorties.push(
                        sort_peid
                        )


                        const tabSorties = new Table({

                            rows:  datrwSorties
                        })

                        datrw =[new TableRow({
                                    children: [

                                        new TableCell({
                                            width: {
                                                size: 100,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({

                                            children: [

                                                new TextRun({
                                                text: item.nom,
                                                color: '636572',
                                                bold: false,
                                                font: "Segoe UI Light"
                                            })
                                            ],
                                                    spacing: {
                                                        before: 100,
                                                        after: 100
                                                    },
                                                        heading: HeadingLevel.HEADING_2,
                                                        alignment: AlignmentType.CENTER,
                                                    })],

                                            columnSpan:5,
                                            borders:{
                                                top:{style: BorderStyle.DOUBLE,size: 1,color: "585858"},
                                                bottom:{style: BorderStyle.DOUBLE,size: 1,color: "585858"},
                                                left:{style: BorderStyle.DOUBLE,size: 1,color: "585858"},
                                                right:{style: BorderStyle.DOUBLE,size: 1,color: "585858"},
                                            },
                                            

                                        }),



                                    ],
                                })]




                        let r = new TableRow({
                                    children: [
                                        new TableCell({
                                            width: {
                                                size: 40,
                                                type: WidthType.PERCENTAGE,
                                            },

                                            children: [
                                                tabEntre

                                            ],
                                            borders:{
                                                top:{style: BorderStyle.NONE,size: 1,color: "ff0000"},
                                                bottom:{style: BorderStyle.DOUBLE,size: 1,color: "585858"},
                                                left:{style: BorderStyle.DOUBLE,size: 1,color: "585858"},
                                                right:{style: BorderStyle.NONE,size: 1,color: "ff0000"},
                                            },

                                            
                                        }),

                                      new TableCell({
                                            width: {
                                                size: 60,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            children: [
                                                tabSorties

                                            ],
                                            borders:{
                                                top:{style: BorderStyle.NONE,size: 1,color: "ff0000"},
                                                bottom:{style: BorderStyle.DOUBLE,size: 1,color: "585858"},
                                                left:{style: BorderStyle.DOUBLE,size: 1,color: "585858"},
                                                right:{style: BorderStyle.DOUBLE,size: 1,color: "585858"},
                                            },

                                        }),
                                    ],
                                })

                        datrw.push(
                        r
                        )



                        
                        const tableRox = new Table({

                            rows:  datrw
                        })

//global Add:
                        let r_global = new TableRow({
                                    children: [
                                        new TableCell({
                                            width: {
                                                size: 11000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            borders:{
                                                top:{style: BorderStyle.NONE,size: 1,color: "ff0000"},
                                                bottom:{style: BorderStyle.NONE,size: 1,color: "ff0000"},
                                                left:{style: BorderStyle.NONE,size: 1,color: "ff0000"},
                                                right:{style: BorderStyle.NONE,size: 1,color: "ff0000"},
                                            },

                                            margins:{
                                                    top: 90,
                                                    bottom: 200,
                                                    left: 40,
                                                    right: 40
                                            },
                                            children: [
                                                tableRox
                                            ],
                                            columnSpan:5
                                        }),


                                    ],
                                })

                        datrwglobal.push(
                        r_global
                        )



                        });

                                const doc = new Document();

                        const table = new Table({

                            rows:  datrwglobal
                        })



                                doc.addSection({


                                    properties: {
                                        page: {
                                            pageNumbers: {
                                                start: 1,
                                                formatType: PageNumberFormat.DECIMAL,
                                            },
                                        },
                                    },
                                    headers: {
                                        default: new Header({
                                            children: [
                                                new Paragraph({
                                                    children: [
                                            new TextRun({
                                                text: "تعاونية حليب الريش",
                                                color: '636572',
                                                bold: true,

                                                })
                                                    ],
                                                border: {
                                                bottom: {
                                                        color: "636572",
                                                        space: 4,
                                                        value: "single",
                                                        size: 10,
                                                    }},
                                                    heading: HeadingLevel.TITLE,
                                                    alignment: AlignmentType.CENTER,
                                                }),
                                            ],
                                        }),
                                    },
                                    footers: {
                                        default: new Footer({
                                            children: [
                                                new Paragraph({
                                                    alignment: AlignmentType.RIGHT,
                                                    children: [
                                                        new TextRun({
                                                            children: ["Page : ", PageNumber.CURRENT],
                                                        }),
                                                        new TextRun({
                                                            children: [" Sur ", PageNumber.TOTAL_PAGES],
                                                        }),
                                                    ],
                                                }),
                                            ],
                                        }),
                                    },



                                    children: [

                                        new Paragraph({



                                            children: [

                                                new TextRun({
                                                text: "Production/ Transformation : "+process,
                                                color: '636572',
                                                bold: false,
                                                font: "Segoe UI Light"
                                            })
                                            ],

                                            heading: HeadingLevel.HEADING_1,
                                            alignment: AlignmentType.CENTER,

                                                spacing: {
                                                    after: 300,
                                                    before: 300
                                                },
                                        }),
                                        

                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "De : "+this.itemFormBon.dateDebut+" Au "+this.itemFormBon.dateFin,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }),

                            ],

                            heading: HeadingLevel.HEADING_2,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),
                        new Paragraph({



                            children: [
                                new TextRun({
                                text: "Total Entrées : "+this.total_entr+"                    Total Production : "+this.total_prod+"",
                                color: 'db8932',
                                bold: true,
                            }),

                            ],

                        
                            heading: HeadingLevel.HEADING_3,
                            alignment: AlignmentType.CENTER,
                                spacing: {
                                    after: 300
                                },
                        }),

                                        table
                                        
                                        ]
                                })







                            Packer.toBlob(doc).then(blob => {

                        saveAs(blob, "Processus_Production.docx");

                            });

                },


        fetchCentre: function () {

                    //chager les depots:
                    this.isLoading=true;
                    this.$http.get('/processActif')
                    .then((result) => {
                                this.procList = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


        },

        handleSubmitBonCMD(){

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                this.fetchListo(this.itemFormBon.proc_id,this.itemFormBon.dateDebut,this.itemFormBon.dateFin)
   

                }


        },

        fetchListo: function (proc,deb,fin) {
                    this.isLoading=true;
                    
                    this.$http.get('/processRappMois/'+proc+'/'+deb+'/'+fin)
                    .then((result) => {
                                this.itemslistprod = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },


    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>